import React, { Component } from 'react';
import {
  MDBAnimation,
  MDBCarousel,
  MDBCarouselCaption,
  MDBCarouselInner,
  MDBCarouselItem,
  MDBView,
  // MDBMask,
  MDBContainer,
  MDBRow,
} from 'mdbreact';

export class CarouselPage extends Component {
  render() {
    return (
      <MDBContainer fluid>
        <MDBRow>
          <MDBCarousel
            activeItem={1}
            length={2}
            showControls={true}
            showIndicators={false}
            id="home-banner"
          >
            <MDBCarouselInner>
              <MDBCarouselItem itemId="1">
                <MDBView>
                  <div
                    style={{
                      display: 'flex',
                      height: '100%',
                      width: '100vw',
                      backgroundColor: '#0f2d3d',
                    }}
                  >
                    <img
                      className="d-block w-100"
                      src="images/banner/banner-2.webp"
                      alt="First slide"
                      style={{ objectFit: 'cover', opacity: 0.3 }}
                    />
                  </div>
                </MDBView>
                <MDBCarouselCaption>
                  <div className="container-xl d-flex">
                    <div className="carousel-caption-inner">
                      {/* <MDBAnimation className="slideInUp" count={1} delay=".5s">
                        <h5 className="ttm-textcolor-grey">
                          Delight your Customers&nbsp;&nbsp;&nbsp;
                        </h5>
                      </MDBAnimation> */}
                      <MDBAnimation className="slideInUp" count={1} delay=".5s">
                        <h2 className="ttm-textcolor-grey">
                          Innovation technologique{' '}
                          <strong className="ttm-textcolor-light-skincolor">sur mesure</strong>
                        </h2>
                      </MDBAnimation>
                      <MDBAnimation className="slideInUp" count={1} delay="1s">
                        <p className="text">
                          Nous concevons des solutions digitales innovantes et personnalisées,
                          adaptées aux besoins spécifiques des clients.
                        </p>
                      </MDBAnimation>
                      <MDBAnimation className="slideInUp" count={1} delay="1.5s">
                        <div>
                          <a
                            href={process.env.PUBLIC_URL + '/projects'}
                            className="ttm-btn ttm-btn-size-md ttm-bgcolor-skincolor mr-3"
                          >
                            <span className="d-flex justify-content-center align-items-center">
                              Découvrez nos réalisations
                              <i className="ti ti-arrow-right ml-2" />
                            </span>
                          </a>
                          <a
                            href={process.env.PUBLIC_URL + '/contact'}
                            className="ttm-btn ttm-btn-size-md ttm-bgcolor-grey"
                          >
                            <span className="d-flex justify-content-center align-items-center">
                              Contactez nous
                              <i className="ti ti-headphone-alt ml-2" />
                            </span>
                          </a>
                        </div>
                      </MDBAnimation>
                    </div>
                  </div>
                </MDBCarouselCaption>
              </MDBCarouselItem>

              <MDBCarouselItem itemId="2">
                <MDBView>
                  <div
                    style={{
                      display: 'flex',
                      height: '100%',
                      width: '100vw',
                      backgroundColor: '#0f2d3d',
                    }}
                  >
                    <img
                      className="d-block w-100"
                      src="images/banner/banner-1.webp"
                      alt="First slide"
                      style={{ objectFit: 'cover', opacity: 0.3 }}
                    />
                  </div>
                </MDBView>
                <MDBCarouselCaption>
                  <div className="container-xl d-flex">
                    <div className="carousel-caption-inner">
                      {/* <MDBAnimation className="slideInUp" count={1} delay="2s">
                        <h5 className="ttm-textcolor-grey">
                          Delight your Customers&nbsp;&nbsp;&nbsp;
                        </h5>
                      </MDBAnimation> */}
                      <MDBAnimation className="slideInUp" count={1} delay=".5s">
                        <h2 className="ttm-textcolor-grey">
                          Une équipe{' '}
                          <strong className="ttm-textcolor-light-skincolor">
                            hautement qualifiée
                          </strong>
                        </h2>
                      </MDBAnimation>
                      <MDBAnimation className="slideInUp" count={1} delay="1s">
                        <p className="text ttm-textcolor-grey">
                          Notre équipe est composée d'experts passionnés et talentueux dans leur
                          domaine. Avec des années d'expérience et une formation approfondie. Nous
                          sommes équipés pour résoudre les problèmes les plus complexes et offrir
                          des solutions innovantes à nos clients.
                        </p>
                      </MDBAnimation>
                      <MDBAnimation className="slideInUp" count={1} delay="1.5s">
                        <p>
                          <a
                            href={process.env.PUBLIC_URL + '/team'}
                            className="ttm-btn ttm-btn-size-md ttm-bgcolor-skincolor ttm-textcolor-white mr-3"
                          >
                            <span className="d-flex justify-content-center align-items-center">
                              Découvrez nos experts
                              <i className="ti ti-arrow-right ml-2" />
                            </span>
                          </a>
                          <a
                            href={process.env.PUBLIC_URL + '/contact'}
                            className="ttm-btn ttm-btn-size-md ttm-bgcolor-grey"
                          >
                            <span className="d-flex justify-content-center align-items-center">
                              Contactez nous
                              <i className="ti ti-headphone-alt ml-2" />
                            </span>
                          </a>
                        </p>
                      </MDBAnimation>
                    </div>
                  </div>
                </MDBCarouselCaption>
              </MDBCarouselItem>
            </MDBCarouselInner>
          </MDBCarousel>
        </MDBRow>
      </MDBContainer>
    );
  }
}

export default CarouselPage;
