import React from 'react';
import Menu from './Menu';
import Mobilemenu from './MobileMenu';
import ImageLoader from '../../containers/ImageLoader';

const Header = () => {
  // const [show, setShow] = React.useState(false);

  // const toggle = () => setShow(!show);

  return (
    <header id="masthead" className="header ttm-header-style-01">
      {/* ttm-topbar-wrapper */}
      <div className="ttm-topbar-wrapper ttm-bgcolor-darkgrey ttm-textcolor-white clearfix">
        <div className="container-xl">
          <div className="row">
            <div className="col-lg-5">
              <ul className="top-contact text-left">
                <li>
                  <i className="fas fa-map-marker-alt" />
                  &nbsp;&nbsp;Makepe, Douala
                </li>
                <li>
                  <i className="far fa-envelope" />
                  &nbsp;&nbsp;
                  <a href="mailto:info@houseinnovationgroup.com">info@houseinnovationgroup.com</a>
                </li>
              </ul>
            </div>
            <div className="col-lg-7">
              <div className="topbar-right text-right d-flex justify-content-end">
                <ul className="top-contact">
                  <li>
                    <i className="far fa-clock" />
                    &nbsp;&nbsp;Horaires: 08:00 - 18:00
                  </li>
                </ul>
                <div className="ttm-social-links-wrapper list-inline">
                  <ul className="social-icons">
                    <li>
                      <a
                        className=" tooltip-bottom"
                        data-tooltip="Linkedin"
                        href="https://www.linkedin.com/company/house-innovation-sarl/"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <i className="fab fa-linkedin-in" />
                      </a>
                    </li>
                    <li>
                      <a
                        className=" tooltip-bottom"
                        data-tooltip="Twitter"
                        href="https://twitter.com/House_INNOV"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <i className="fab fa-twitter" />
                      </a>
                    </li>
                    <li>
                      <a
                        className="tooltip-bottom"
                        data-tooltip="Facebook"
                        href="https://www.facebook.com/housinnov"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <i className="fab fa-facebook-f" />
                      </a>
                    </li>
                    <li>
                      <a
                        className=" tooltip-bottom"
                        data-tooltip="Instagram"
                        href="https://www.instagram.com/house_innovation_group/"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <i className="fab fa-instagram" />
                      </a>
                    </li>
                  </ul>
                </div>
                <div className="header-btn">
                  <a
                    className="ttm-btn ttm-btn-size-md ttm-bgcolor-skincolor rounded-none"
                    href="/contact"
                  >
                    Envoyer un message
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* ttm-topbar-wrapper end */}

      {/* ttm-header-wrap */}
      <div className="ttm-header-wrap">
        <div id="navbar" className="ttm-stickable-header clearfix">
          <div className="site-header-menu">
            <div className="container-xl">
              {/* site-branding */}
              {/* <div className="site-branding hide-on-desktop">
                <a
                  className="home-link"
                  href={process.env.PUBLIC_URL + '/'}
                  title="House Innovation"
                  rel="home"
                >
                  <ImageLoader
                    id="logo-img"
                    className="img-fluid"
                    alt="logo"
                    src="images/logo.png"
                  />
                </a>
              </div> */}

              <div className="site-branding d-none d-lg-block">
                <a
                  className="home-link"
                  href={process.env.PUBLIC_URL + '/'}
                  title="House Innovation"
                  rel="home"
                >
                  <ImageLoader
                    id="logo-img"
                    className="img-fluid"
                    alt="logo"
                    src="images/logo-full.png"
                  />
                </a>
              </div>
              {/* site-branding end*/}

              {/*site-navigation */}
              <div className="site-navigation">
                <Menu />

                <div className="mobilemenu">
                  <Mobilemenu />
                </div>
              </div>
              {/*site-navigation end*/}
            </div>
          </div>
        </div>
      </div>
      {/* ttm-header-wrap end*/}
    </header>
  );
};

export default Header;
