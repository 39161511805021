import React, { Component } from 'react';
import {
  MDBNavbar,
  MDBNavbarNav,
  MDBNavItem,
  MDBCollapse,
  MDBDropdown,
  MDBDropdownToggle,
  MDBDropdownMenu,
  MDBDropdownItem,
  MDBHamburgerToggler,
} from 'mdbreact';
import services from '../../core/data/services.json';
import ImageLoader from '../../containers/ImageLoader';

class Mobilemenu extends Component {
  state = {
    collapse1: false,
    collapseID: '',
  };

  toggleCollapse = (collapseID) => () => {
    this.setState((prevState) => ({
      collapseID: prevState.collapseID !== collapseID ? collapseID : '',
    }));
  };

  toggleSingleCollapse = (collapseId) => {
    this.setState({
      ...this.state,
      [collapseId]: !this.state[collapseId],
    });
  };

  render() {
    return (
      <MDBNavbar className="d-flex justify-content-between align-items-center">
        <MDBHamburgerToggler
          id="hamburger1"
          onClick={() => this.toggleSingleCollapse('collapse1')}
        />

        <div className="mobile-branding">
          <a href={process.env.PUBLIC_URL + '/'} title="House Innovation">
            <ImageLoader id="logo-img" className="img-fluid" alt="logo" src="images/logo.png" />
          </a>
        </div>
        <MDBCollapse isOpen={this.state.collapse1} navbar>
          <MDBNavbarNav left>
            <MDBNavItem active>
              <a className="nav-link" href={process.env.PUBLIC_URL + '/'}>
                Accueil
              </a>
            </MDBNavItem>

            <MDBNavItem>
              <MDBDropdown>
                <MDBDropdownToggle nav caret>
                  <span>À propos</span>
                </MDBDropdownToggle>
                <MDBDropdownMenu>
                  <MDBDropdownItem href={process.env.PUBLIC_URL + '/aboutus'}>
                    Qui sommes-nous ?
                  </MDBDropdownItem>
                  <MDBDropdownItem href={process.env.PUBLIC_URL + '/team'}>
                    Nos experts
                  </MDBDropdownItem>
                </MDBDropdownMenu>
              </MDBDropdown>
            </MDBNavItem>

            <MDBNavItem>
              <MDBDropdown>
                <MDBDropdownToggle nav caret>
                  <span>Nos services</span>
                </MDBDropdownToggle>
                <MDBDropdownMenu>
                  {services.map((service) => (
                    <MDBDropdownItem
                      key={service.name}
                      href={process.env.PUBLIC_URL + service.route}
                    >
                      {service.name}
                    </MDBDropdownItem>
                  ))}
                </MDBDropdownMenu>
              </MDBDropdown>
            </MDBNavItem>

            <MDBNavItem>
              <a className="nav-link" href={process.env.PUBLIC_URL + '/projects'}>
                Nos réalisations
              </a>
            </MDBNavItem>

            <MDBNavItem>
              <a className="nav-link" href={process.env.PUBLIC_URL + '/blogs'}>
                Blog
              </a>
            </MDBNavItem>

            {/* <MDBNavItem>
                <a className="nav-link" href={process.env.PUBLIC_URL + '/'}>Carrières</a>
              </MDBNavItem> */}

            <MDBNavItem>
              <a className="nav-link" href={process.env.PUBLIC_URL + '/contact'}>
                Contact
              </a>
            </MDBNavItem>
          </MDBNavbarNav>
        </MDBCollapse>
      </MDBNavbar>
    );
  }
}

export default Mobilemenu;
