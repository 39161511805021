import React, { Component } from 'react';
import Header from '../layout/Header';
import team from '../../core/data/team.json';
import ImageLoader from '../../containers/ImageLoader';

export class Team extends Component {
  render() {
    return (
      <div className="site-main">
        <Header />

        {/* page-title */}
        <div className="ttm-page-title-row">
          <div className="container-xl">
            <div className="row">
              <div className="col-md-12">
                <div className="title-box text-center">
                  <div className="page-title-heading">
                    <h1 className="title">Nos experts</h1>
                  </div>
                  {/* /.page-title-captions */}
                  <div className="breadcrumb-wrapper">
                    <span>
                      <a title="Homepage" href={process.env.PUBLIC_URL + '/'}>
                        <i className="ti ti-home" />
                        &nbsp;&nbsp;Accueil
                      </a>
                    </span>
                    <span className="ttm-bread-sep">&nbsp; : : &nbsp;</span>
                    <span>experts</span>
                  </div>
                </div>
              </div>
              {/* /.col-md-12 */}
            </div>
            {/* /.row */}
          </div>
          {/* /.container */}
        </div>

        <section className="ttm-row grid-section ttm-bgcolor-grey clearfix">
          <div className="container-xl">
            {/* row */}
            <div className="row">
              {team.map((member) => (
                <div className="col-md-6 col-lg-4 col-sm-6" key={member.name}>
                  <div className="featured-imagebox featured-imagebox-team style1 m-3">
                    <div className="featured-thumbnail">
                      {/* featured-thumbnail */}
                      <ImageLoader src={member.image} className="img-fluid" alt={member.name} />
                      <div className="ttm-social-links-wrapper">
                        <ul className="social-icons list-inline">
                          {/* social-icons */}
                          {member.socials.facebook && (
                            <li className="social-facebook">
                              <a
                                href={member.socials.facebook}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                <i className="fab fa-facebook-f" />
                              </a>
                            </li>
                          )}

                          {member.socials.twitter && (
                            <li className="social-twitter">
                              <a
                                href={member.socials.twitter}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                <i className="fab fa-twitter" />
                              </a>
                            </li>
                          )}

                          {member.socials.linkedin && (
                            <li className="social-linkedin">
                              <a
                                href={member.socials.linkedin}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                <i className="fab fa-linkedin-in" />
                              </a>
                            </li>
                          )}
                        </ul>
                      </div>
                    </div>
                    <div className="featured-content box-shadow">
                      <div className="featured-title">
                        {/* featured-title */}
                        <h5>{member.name}</h5>
                      </div>
                      <p className="category">{member.title}</p>
                      {/* category */}
                    </div>
                  </div>
                </div>
              ))}
            </div>
            {/* row end*/}
          </div>
        </section>
      </div>
    );
  }
}

export default Team;
