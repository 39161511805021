import React from 'react';
import Sidebar from './ServiceSidebar';
import Header from '../layout/Header';
import ImageLoader from '../../containers/ImageLoader';

const ConseilService = () => {
  return (
    <div className="site-main">
      <Header />

      {/* page-title */}
      <div className="ttm-page-title-row ttm-bgcolor-grey">
        <div className="container-xl">
          <div className="row">
            <div className="col-md-12">
              <div className="title-box text-center">
                <div className="page-title-heading">
                  <h1 className="title">Conseil en informatique</h1>
                </div>
                {/* /.page-title-captions */}
                <div className="breadcrumb-wrapper">
                  <span>
                    <a title="Homepage" href={process.env.PUBLIC_URL + '/'}>
                      <i className="ti ti-home" />
                      &nbsp;&nbsp;Accueil
                    </a>
                  </span>
                  <span className="ttm-bread-sep">&nbsp; : : &nbsp;</span>
                  <span>Service</span>
                </div>
              </div>
            </div>
            {/* /.col-md-12 */}
          </div>
          {/* /.row */}
        </div>
        {/* /.container */}
      </div>

      <div className="sidebar ttm-sidebar ttm-bgcolor-white clearfix">
        <div className="container-xl">
          {/* row */}
          <div className="row">
            <div className="col-lg-9 content-area order-lg-2">
              {/* ttm-service-single-content-are */}
              {/* ttm-service-single-content-are */}
              <div className="ttm-service-single-content-area">
                {/* section title */}
                <div className="section-title with-desc text-left clearfix">
                  <div className="title-header">
                    <h5>Pour vous, nous...</h5>
                    <h2 className="title">Aidons à améliorer votre système d'information</h2>
                  </div>
                </div>
                {/* section title end */}
                <div className="ttm_single_image-wrapper service mb-35">
                  <ImageLoader className="img-fluid" src="images/svg/advice.svg" alt="advice" />
                </div>
                <div className="ttm-service-description">
                  {/* <h3>App Installation &amp; Cloud Services</h3> */}
                  <p>
                    Le conseil en informatique est un service qui aide les entreprises à améliorer
                    leur système d'information (SI). Les consultants en informatique ont une
                    expertise dans le domaine de l'informatique et peuvent fournir des conseils sur
                    une variété de sujets, tels que la sécurité, les réseaux, la gestion des données
                    et le développement de logiciels.
                  </p>

                  <p>
                    Les consultants en informatique peuvent aider les entreprises à résoudre des
                    problèmes spécifiques, tels que la mise en place d'un nouveau système
                    informatique ou la résolution d'une faille de sécurité. Ils peuvent également
                    aider les entreprises à améliorer leur SI de manière plus générale, en
                    fournissant des conseils sur l'optimisation des processus, la réduction des
                    coûts et l'amélioration de la performance.
                  </p>

                  <div>
                    Voici quelques-unes des tâches courantes que peuvent effectuer les consultants
                    en informatique :
                    <ul className="list-defaut">
                      <li>Analyser les besoins de l'entreprise</li>
                      <li>Concevoir et mettre en œuvre des solutions informatiques</li>
                      <li>Former les employés de l'entreprise sur les nouvelles technologies</li>
                      <li>Gérer des projets informatiques</li>
                      <li>
                        Conseiller l'entreprise sur les meilleures pratiques en matière
                        d'informatique
                      </li>
                    </ul>
                  </div>

                  <p>
                    Nous avons dans notre équipe des experts en informatique qualifiés qui peuvent
                    vous aidez à améliorer votre système d'information afin d'atteindre vos
                    objectifs.
                  </p>
                </div>
              </div>
              {/* ttm-service-single-content-are end */}
            </div>
            <div className="col-lg-3 widget-area">
              <Sidebar />
            </div>
          </div>
          {/* row end */}
        </div>
      </div>
    </div>
  );
};

export default ConseilService;
