import * as yup from 'yup';

export const CommentFormSchema = yup
  .object({
    author: yup.string().required(),
    email: yup.string().email(),
    content: yup.string().required(),
    save: yup.boolean(),
  })
  .required();
