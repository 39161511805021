import React from 'react';
import services from '../../core/data/services.json';

const Menu = () => {
  return (
    <nav id="menu" role="navigation" className="menu">
      <ul>
        <li className="menu">
          <a href={process.env.PUBLIC_URL + '/'}>Accueil</a>
        </li>

        <li className="menu-hasdropdown">
          <a
            href={process.env.PUBLIC_URL + '/'}
            onClick={(e) => e.preventDefault()}
            className="nav-item"
          >
            À propos
          </a>
          <ul className="menu-dropdown">
            <li>
              <a className="nav-item" href={process.env.PUBLIC_URL + '/aboutus'}>
                Qui sommes-nous ?
              </a>
            </li>
            <li>
              <a className="nav-item" href={process.env.PUBLIC_URL + '/team'}>
                Nos experts
              </a>
            </li>
          </ul>
        </li>

        <li className="menu-hasdropdown">
          <a
            href={process.env.PUBLIC_URL + '/'}
            onClick={(e) => e.preventDefault()}
            className="nav-item"
          >
            Nos services
          </a>
          <ul className="menu-dropdown">
            {services.map((service) => (
              <li key={service.name}>
                <a href={process.env.PUBLIC_URL + service.route}>{service.name}</a>
              </li>
            ))}
          </ul>
        </li>

        <li className="menu">
          <a href={process.env.PUBLIC_URL + '/projects'}>Nos réalisations</a>
        </li>

        <li className="menu">
          <a href={process.env.PUBLIC_URL + '/blogs'}>Blog</a>
        </li>

        {/* <li className="menu">
          <a href="#carrieres">Carrières</a>
        </li> */}

        <li className="menu">
          <a href={process.env.PUBLIC_URL + '/contact'}>Contact</a>
        </li>
      </ul>
    </nav>
  );
};

export default Menu;
