import React from 'react';
import services from '../../core/data/services.json';
import ImageLoader from '../../containers/ImageLoader';

import { useLocation } from 'react-router-dom';

const Sidebar = () => {
  const { pathname } = useLocation();

  return (
    <div>
      <div className="widget widget-nav-menu">
        <ul className="widget-menu">
          {services.map((service) => (
            <li key={service.name}>
              <a
                className={pathname.includes(service.route) ? 'active' : ''}
                href={process.env.PUBLIC_URL + service.route}
              >
                {service.name}
              </a>
            </li>
          ))}
        </ul>
      </div>
      <div className="widget widget-text">
        <h3 className="widget-title">À propos de nous</h3>
        <div className="ttm-author-widget">
          <div className="author-widget_img">
            <ImageLoader
              className="author-img img-fluid"
              src="images/about-img.jpeg"
              alt="HOUSE INNOVATION"
            />
          </div>
          <h4 className="author-name">House Innovation</h4>
          <p className="author-widget_text">
            Nous vous accompagnons de bout en bout dans la digitalisation de votre structure
          </p>
        </div>
      </div>
      <div className="widget widget-download">
        <h3 className="widget-title">Brochure</h3>
        <ul className="download">
          <li>
            <i className="far fa-file-pdf" />
            <a title="brochure-house-innovation" href="brochure-house-innovation.pdf" download>
              Télécharger
            </a>
          </li>
        </ul>
      </div>
      <div className="widget widget_media_image">
        <div className="banner-img-box ttm-textcolor-white text-left">
          <div className="featured-content featured-content-banner">
            <i className="flaticon flaticon-call" />
            <div className="featured-title ttm-box-title">
              <h5>Comment pouvons-nous vous aider?</h5>
            </div>
            <div className="featured-desc">
              <p>Si vous avez besoin d'aide, n'hésitez pas à nous contacter.</p>
            </div>
            <ul>
              <li>
                {/* <i className="fa fa-phone" /> */}
                +237 697 098 859
              </li>
              <li>
                {/* <i className="far fa-envelope" /> */}
                <a href="mailto:info@example.com">info@houseinnovationgroup.com</a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Sidebar;
