/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from 'react';
import checkInViewIntersectionObserver from '../core/utils/check-In-view-port-intersection-observer.util';

// export interface ImageLoaderProps extends ImgHTMLAttributes<HTMLImageElement> {
//   containerClassName?: string;
//   prevImageHorizontal?: boolean;
//   style?: CSSProperties;
// }

const ImageLoader = ({
  containerClassName,
  alt = 'hi-imgs',
  src,
  prevImageHorizontal = false,
  className = '',
  style = {},
  ...args
}) => {
  let isMounted = useRef(false);

  const _containerRef = useRef(null);

  let _imageEl = null;

  const placeholderImage = prevImageHorizontal
    ? 'images/placeholder-h.png'
    : 'images/placeholder.png';

  const [imgSrc, setImgSrc] = useState();

  const _initActions = async () => {
    setImgSrc(placeholderImage);
    _checkInViewPort();
  };

  const _checkInViewPort = () => {
    if (!_containerRef.current) return;
    checkInViewIntersectionObserver({
      target: _containerRef.current,
      distanceFromEnd: 0,
      callback: _imageOnViewPort,
    });
  };

  const _imageOnViewPort = () => {
    if (!src) {
      _handleImageLoaded();
      return true;
    }
    _imageEl = new Image();

    if (_imageEl) {
      _imageEl.src = src;
      _imageEl.addEventListener('load', _handleImageLoaded);
    }

    return true;
  };

  const _handleImageLoaded = () => {
    if (!isMounted.current) return;
    setImgSrc(src || placeholderImage);
  };

  useEffect(() => {
    isMounted.current = true;

    _initActions();

    return () => {
      isMounted.current = false;
    };
  }, [src]);

  return (
    <img ref={_containerRef} src={imgSrc} className={className} alt={alt} {...args} style={style} />
  );
};

export default ImageLoader;
