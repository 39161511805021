import React from 'react';

const Blogsidebar = ({ blog }) => {
  const { categories, tags } = blog;
  return (
    <div>
      {categories && (
        <aside className="widget widget-categories">
          <h3 className="widget-title">Categories</h3>
          <ul>
            {(categories || []).map((categorie) => (
              <li key={categorie.name}>
                <a
                  key={categorie}
                  href={process.env.PUBLIC_URL + `/blogs?categorie=${categorie.name}`}
                >
                  {categorie.name}
                </a>
              </li>
            ))}
          </ul>
        </aside>
      )}

      {/* <aside className="widget post-widget">
        <h3 className="widget-title">Articles récents</h3>
        <ul className="widget-post ttm-recent-post-list">
          <li>
            <a href={process.env.PUBLIC_URL + '/Single_blog'}>
              <img src="https://via.placeholder.com/150X150/444444.jpg" alt="post-img" />
            </a>
            <a href={process.env.PUBLIC_URL + '/Single_blog'}>
              Define World Best IT Solution Technology
            </a>
            <span className="post-date">
              <i className="fa fa-calendar" />
              May 01, 2019
            </span>
          </li>
          <li>
            <a href={process.env.PUBLIC_URL + '/Single_blog'}>
              <img src="https://via.placeholder.com/150X150/444444.jpg" alt="post-img" />
            </a>
            <a href={process.env.PUBLIC_URL + '/Single_blog'}>
              You Must Try 20 Secret Of Digital Transform
            </a>
            <span className="post-date">
              <i className="fa fa-calendar" />
              May 03, 2019
            </span>
          </li>
          <li>
            <a href={process.env.PUBLIC_URL + '/Single_blog'}>
              <img src="https://via.placeholder.com/150X150/444444.jpg" alt="post-img" />
            </a>
            <a href={process.env.PUBLIC_URL + '/Single_blog'}>
              10 PHP Frameworks You Need To Use Anywhere
            </a>
            <span className="post-date">
              <i className="fa fa-calendar" />
              May 05, 2019
            </span>
          </li>
        </ul>
      </aside> */}

      <aside className="widget tagcloud-widget">
        <h3 className="widget-title">Tags</h3>
        <div className="tagcloud">
          {(tags || []).map((tag) => (
            <a
              className="tag-cloud-link"
              key={tag}
              href={process.env.PUBLIC_URL + `/blogs?tag=${tag}`}
            >
              {tag}
            </a>
          ))}
        </div>
      </aside>
    </div>
  );
};

export default Blogsidebar;
