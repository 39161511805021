import React, { Component } from 'react';
import Sidebar from './ServiceSidebar';
import Header from '../layout/Header';
import ImageLoader from '../../containers/ImageLoader';

export class WebService extends Component {
  render() {
    return (
      <div className="site-main">
        <Header />

        {/* page-title */}
        <div className="ttm-page-title-row ttm-bgcolor-grey">
          <div className="container-xl">
            <div className="row">
              <div className="col-md-12">
                <div className="title-box text-center">
                  <div className="page-title-heading">
                    <h1 className="title">Développement webs</h1>
                  </div>
                  {/* /.page-title-captions */}
                  <div className="breadcrumb-wrapper">
                    <span>
                      <a title="Homepage" href={process.env.PUBLIC_URL + '/'}>
                        <i className="ti ti-home" />
                        &nbsp;&nbsp;Accueil
                      </a>
                    </span>
                    <span className="ttm-bread-sep">&nbsp; : : &nbsp;</span>
                    <span>Service</span>
                  </div>
                </div>
              </div>
              {/* /.col-md-12 */}
            </div>
            {/* /.row */}
          </div>
          {/* /.container */}
        </div>

        <div className="sidebar ttm-sidebar ttm-bgcolor-white clearfix">
          <div className="container-xl">
            {/* row */}
            <div className="row">
              <div className="col-lg-9 content-area order-lg-2">
                {/* ttm-service-single-content-are */}
                {/* ttm-service-single-content-are */}
                <div className="ttm-service-single-content-area">
                  {/* section title */}
                  <div className="section-title with-desc text-left clearfix">
                    <div className="title-header">
                      <h5>Pour vous, nous...</h5>
                      <h2 className="title">Développons des solutions webs</h2>
                    </div>
                  </div>
                  {/* section title end */}
                  <div className="ttm_single_image-wrapper service mb-35">
                    <ImageLoader
                      className="img-fluid"
                      src="images/svg/web.svg"
                      alt="single-img-twelve"
                    />
                  </div>
                  <div className="ttm-service-description">
                    {/* <h4>Une application web c'est quoi ?</h4> */}
                    <p>
                      Une solution web est un ensemble de logiciels et de services qui fonctionnent
                      ensemble pour fournir une fonction ou un service spécifique. Les solutions web
                      peuvent être utilisées par les entreprises, les organisations et les
                      particuliers pour une variété de tâches, telles que la gestion de la relation
                      client, l'analyse des données et la vente en ligne.
                    </p>

                    <p>
                      Les solutions web sont généralement conçues pour être faciles à utiliser et à
                      gérer, et elles peuvent être accessibles à partir de n'importe quel endroit
                      disposant d'une connexion Internet. Cela en fait un outil précieux pour les
                      entreprises qui souhaitent améliorer leur productivité et leur efficacité.
                    </p>

                    <div>
                      Voici quelques exemples de solutions web courantes :
                      <ul className="list-defaut">
                        <li>
                          <strong>Les systèmes de gestion de la relation client (CRM)</strong>{' '}
                          permettent aux entreprises de suivre les interactions avec leurs clients.
                        </li>
                        <li>
                          <strong>Les progiciels de gestion d'entreprise (ERP)</strong> permettent
                          aux entreprises de gérer leurs opérations commerciales, telles que la
                          comptabilité, la gestion des stocks et la gestion des projets.
                        </li>
                        <li>
                          <strong>Les plateformes de commerce électronique</strong> permettent aux
                          entreprises de vendre leurs produits et services en ligne.
                        </li>
                      </ul>
                    </div>

                    <p>
                      Les solutions web peuvent être personnalisées pour répondre à vos besoins
                      spécifiques. Par exemple, vous pouvez choisir d'utiliser un CRM pour suivre
                      vos interactions avec ses clients ou un ERP pour gérer vos opérations
                      commerciales. Ces solutions contriburons à l'amélioration de votre
                      productivité, votre efficacité et votre communication. Elles peuvent également
                      aider à réduire les coûts et à augmenter les revenus.
                    </p>
                    {/* separator */}
                    <div className="separator">
                      <div className="sep-line mt-35 mb-35 res-991-mtb-20" />
                    </div>
                    {/* separator */}
                    <div className="row">
                      <div className="col-md-7">
                        <p className="mb-35 res-991-mb-20">
                          Si vous envisagez d'utiliser une solution web, il est important de choisir
                          la solution qui convient le mieux à vos besoins. Il est également
                          important de vous assurer que la solution est compatible avec votre
                          infrastructure informatique et que vous disposez des compétences
                          nécessaires pour l'utiliser.
                        </p>

                        <p className="mb-35 res-991-mb-20">
                          Nous proposons une variété de solutions Web personnalisées pour répondre
                          aux besoins spécifiques de nos clients. Notre équipe d'experts vous aidera
                          à choisir la solution qui convient le mieux à vos activités et à la mettre
                          en œuvre de manière efficace. Nous offrons également un service client de
                          qualité pour vous accompagner tout au long du processus.
                        </p>
                      </div>
                      <div className="col-md-5">
                        <div className="ttm_single_image-wrapper res-767-mt-20">
                          <ImageLoader
                            className="img-fluid"
                            src="images/svg/web-propose.svg"
                            alt="web-propose"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* ttm-service-single-content-are end */}
              </div>
              <div className="col-lg-3 widget-area">
                <Sidebar />
              </div>
            </div>
            {/* row end */}
          </div>
        </div>
      </div>
    );
  }
}

export default WebService;
