import React, { Component } from 'react';
import Sidebar from './ServiceSidebar';
import Header from '../layout/Header';
import ImageLoader from '../../containers/ImageLoader';

export class MobileService extends Component {
  render() {
    return (
      <div className="site-main">
        <Header />

        {/* page-title */}
        <div className="ttm-page-title-row ttm-bgcolor-grey">
          <div className="container-xl">
            <div className="row">
              <div className="col-md-12">
                <div className="title-box text-center">
                  <div className="page-title-heading">
                    <h1 className="title">Développement mobile</h1>
                  </div>
                  {/* /.page-title-captions */}
                  <div className="breadcrumb-wrapper">
                    <span>
                      <a title="Homepage" href={process.env.PUBLIC_URL + '/'}>
                        <i className="ti ti-home" />
                        &nbsp;&nbsp;Accueil
                      </a>
                    </span>
                    <span className="ttm-bread-sep">&nbsp; : : &nbsp;</span>
                    <span>Service</span>
                  </div>
                </div>
              </div>
              {/* /.col-md-12 */}
            </div>
            {/* /.row */}
          </div>
          {/* /.container */}
        </div>

        <div className="sidebar ttm-sidebar ttm-bgcolor-white clearfix">
          <div className="container-xl">
            {/* row */}
            <div className="row">
              <div className="col-lg-9 content-area order-lg-2">
                {/* ttm-service-single-content-are */}
                {/* ttm-service-single-content-are */}
                <div className="ttm-service-single-content-area">
                  {/* section title */}
                  <div className="section-title with-desc text-left clearfix">
                    <div className="title-header">
                      <h5>Pour vous, nous...</h5>
                      <h2 className="title">Développons des solutions mobiles</h2>
                    </div>
                  </div>
                  {/* section title end */}
                  <div className="ttm_single_image-wrapper service mb-35">
                    <ImageLoader
                      className="img-fluid"
                      src="images/svg/mobile.svg"
                      alt="single-img-twelve"
                    />
                  </div>
                  <div className="ttm-service-description">
                    {/* <h3>App Installation &amp; Cloud Services</h3> */}
                    <p>
                      Une solution mobile est un logiciel ou un service conçu pour être utilisé sur
                      un appareil mobile, tel qu'un smartphone ou une tablette. Les solutions
                      mobiles peuvent être utilisées pour une variété de tâches, telles que la
                      navigation, la messagerie, la gestion des finances et le divertissement.
                    </p>

                    <div>
                      Voici quelques exemples de solutions mobiles populaires :
                      <ul className="list-defaut">
                        <li>
                          <strong>Les applications de navigation</strong>, telles que Google Maps et
                          Waze
                        </li>
                        <li>
                          <strong>Les applications de messagerie</strong>, telles que WhatsApp et
                          Messenger
                        </li>
                        <li>
                          <strong>Les applications de gestion des finances</strong>, telles que Mint
                          et Personal Capital
                        </li>
                        <li>
                          <strong>Les applications de divertissement</strong>, telles que Netflix,
                          Hulu et Spotify
                        </li>
                      </ul>
                    </div>

                    <div>
                      Les solutions mobiles offrent de nombreux avantages, tels que :
                      <ul className="list-defaut">
                        <li>
                          <strong>La portabilité</strong> : les appareils mobiles peuvent être
                          emportés partout, ce qui permet aux utilisateurs d'accéder à leurs
                          applications et services à tout moment.
                        </li>
                        <li>
                          <strong>La connectivité</strong> : les appareils mobiles permettent aux
                          utilisateurs de rester connectés à Internet, ce qui leur permet d'accéder
                          aux informations et aux services dont ils ont besoin.
                        </li>
                        <li>
                          <strong>La personnalisation</strong> : les utilisateurs peuvent
                          personnaliser leurs solutions mobiles en installant les applications et
                          services qui leur conviennent.
                        </li>
                        <li>
                          <strong>La sécurité</strong> : les solutions mobiles peuvent être
                          sécurisées par des mots de passe et des fonctionnalités de sécurité
                          supplémentaires.
                        </li>
                      </ul>
                    </div>

                    {/* separator */}
                    <div className="separator">
                      <div className="sep-line mt-35 mb-35 res-991-mtb-20" />
                    </div>
                    {/* separator */}
                    <div className="row">
                      <div className="col-md-7">
                        {/* <h3>Digital Planning</h3> */}
                        <p>
                          Les solutions mobiles ont révolutionné la façon dont nous interagissons
                          avec le monde qui nous entoure. Elles nous permettent de rester connectés,
                          d'être productifs et de nous divertir où que nous soyons.
                        </p>
                        <p>
                          Nous proposons une variété de solutions mobiles pour répondre a vos
                          besoins. Nos solutions sont conçues pour être faciles à utiliser,
                          intuitives et sécurisées. Nous offrons également un service client de
                          qualité pour vous aider à tirer le meilleur parti de vos solutions
                          mobiles.
                        </p>
                      </div>
                      <div className="col-md-5">
                        <div className="ttm_single_image-wrapper res-767-mt-20">
                          <ImageLoader
                            className="img-fluid"
                            src="images/svg/mobile-propose.svg"
                            alt="mobile-propose"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* ttm-service-single-content-are end */}
              </div>
              <div className="col-lg-3 widget-area">
                <Sidebar />
              </div>
            </div>
            {/* row end */}
          </div>
        </div>
      </div>
    );
  }
}

export default MobileService;
