import { QueryClient, useMutation, useQuery } from '@tanstack/react-query';
import { getAllBlogs, getBlog, saveBlogComment } from '../../core/services/blog.service';

const queryClient = new QueryClient();

export const useGetAllBlogs = (limit = null) => {
  return useQuery({
    queryKey: ['get-all-blogs', limit],
    queryFn: () => getAllBlogs(limit),
    initialData: [],
  });
};

export const useGetBlog = (slug) => {
  return useQuery({
    queryKey: ['get-blog', slug],
    queryFn: () => getBlog(slug),
    enabled: Boolean(slug),
    initialData: null,
  });
};

export const useSaveBlogComment = (slug) => {
  return useMutation({
    mutationKey: ['save-blog-comment'],
    mutationFn: (data) => saveBlogComment(slug, data),
    onSuccess: () => {
      console.log('SLUG', slug);
      queryClient.invalidateQueries({ queryKey: ['get-blog', slug] });
    },
  });
};
