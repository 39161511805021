import http from '../common/api-http';

const source = 'articles';

export const getAllBlogs = async (limit) => {
  try {
    const rs = await http.get(source + '/publish' + (limit ? `?limit=${limit}` : ''));
    return Promise.resolve(rs.data.data);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const getBlog = async (slug) => {
  try {
    const rs = await http.get(source + `/publish/${slug}`);
    return Promise.resolve(rs.data.data);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const saveBlogComment = async (slug, data) => {
  try {
    const rs = await http.post(source + `/${slug}/comments`, data);
    return Promise.resolve(rs.data.data);
  } catch (error) {
    return Promise.reject(error);
  }
};
