import React, { Component } from 'react';
import Slider from 'react-slick';
import CountUp from 'react-countup';
import Header from '../layout/Header';
import ImageLoader from '../../containers/ImageLoader';
import clients from '../../core/data/clients.json';

export class Aboutus extends Component {
  render() {
    const slick_slider_partners = {
      dots: false,
      arrow: false,
      autoplay: true,
      infinite: true,
      speed: 700,
      slidesToShow: 4,
      slidesToScroll: 1,

      responsive: [
        {
          breakpoint: 1199,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 3,
          },
        },
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 3,
          },
        },
        {
          breakpoint: 680,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
            dots: true,
          },
        },
        {
          breakpoint: 575,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            dots: true,
          },
        },
      ],
    };

    return (
      <div className="site-main">
        <Header />

        {/* page-title */}
        <div className="ttm-page-title-row ttm-bgcolor-grey">
          <div className="container-xl">
            <div className="row">
              <div className="col-md-12">
                <div className="title-box text-center">
                  <div className="page-title-heading">
                    <h1 className="title">Qui sommes nous ?</h1>
                  </div>
                  {/* /.page-title-captions */}
                  <div className="breadcrumb-wrapper">
                    <span>
                      <a title="Homepage" href={process.env.PUBLIC_URL + '/'}>
                        <i className="ti ti-home" />
                        &nbsp;&nbsp;Accueil
                      </a>
                    </span>
                    <span className="ttm-bread-sep">&nbsp; : : &nbsp;</span>
                    <span>À propos de nous</span>
                  </div>
                </div>
              </div>
              {/* /.col-md-12 */}
            </div>
            {/* /.row */}
          </div>
          {/* /.container */}
        </div>

        {/* aboutus-section */}
        <section className="ttm-row aboutus-section clearfix">
          <div className="container-xl">
            <div className="row">
              <div className="col-lg-6">
                {/* section title */}
                <div className="section-title with-desc clearfix">
                  <div className="title-header">
                    <h5>Bienvenue chez nous</h5>
                    <h2 className="title">
                      Qui sommes <span>nous</span> ?
                    </h2>
                  </div>
                  <div className="title-desc">
                    <p>
                      House Innovation SARL née officiellement en 2018, est une Entreprise
                      spécialisée dans l’innovation technologique et le NTIC qui propose une offre
                      adaptée aux besoins du clients, soit une équipe d’ingénieurs qualifiés, un
                      service après-vente de qualité, une maintenance de développement des logiciels
                      adéquats. En effet, L’Entreprise a des atouts sur la capacité de s’adapter au
                      marché, mais aussi bouleverse les codes, soucieux de changer le monde est
                      entourée de meilleurs profils qui travaille et coopère ensemble ainsi pour
                      prôner l’excellence, la collaboration et les risques qui en découlent afin de
                      maximiser le contentement du client qui est ROI.
                    </p>
                    <p>
                      Notre mission pricipale est de développer des outils à la pointe de la
                      technologie pour permettre aux entreprises comme aux particuliers et
                      professionnels de réaliser des profits et améliorer leur performance.
                    </p>
                  </div>

                  <div className="title-desc mt-20 text-left d-block d-md-none">
                    <p>Nos services sont divers et variés:</p>
                    {/* row */}
                    <div className="row no-gutters mt-4">
                      <div className="col-md-6 col-lg-6 col-sm-6">
                        <ul className="ttm-list ttm-list-style-icon">
                          <li>
                            <i className="far fa-circle ttm-textcolor-skincolor" />
                            <span className="ttm-list-li-content">Développement web </span>
                          </li>
                          <li>
                            <i className="far fa-circle ttm-textcolor-skincolor" />
                            <span className="ttm-list-li-content">Développement de progiciels</span>
                          </li>
                          <li>
                            <i className="far fa-circle ttm-textcolor-skincolor" />
                            <span className="ttm-list-li-content">Design graphique</span>
                          </li>
                        </ul>
                      </div>
                      <div className="col-md-6 col-lg-6 col-sm-6">
                        <ul className="ttm-list ttm-list-style-icon">
                          <li>
                            <i className="far fa-circle ttm-textcolor-skincolor" />
                            <span className="ttm-list-li-content">Développement mobile</span>
                          </li>
                          <li>
                            <i className="far fa-circle ttm-textcolor-skincolor" />
                            <span className="ttm-list-li-content">Robotique mobile </span>
                          </li>
                          <li>
                            <i className="far fa-circle ttm-textcolor-skincolor" />
                            <span className="ttm-list-li-content">Intelligence artificielle</span>
                          </li>
                        </ul>
                      </div>
                    </div>
                    {/* row END*/}
                  </div>
                </div>
                {/* section title end */}

                {/* separator */}
                <div className="separator">
                  <div className="sep-line mt-4 mb-4" />
                </div>
                {/* separator */}
                <div className="fs-16 ttm-textcolor-darkgrey">
                  <span>
                    Appelez pour{' '}
                    <a
                      href={process.env.PUBLIC_URL + '/contact'}
                      className="fw-500 ttm-textcolor-darkgrey"
                    >
                      <u>en savoir plus</u>
                    </a>
                  </span>
                  <p>
                    <strong className="fs-18">(+237) 697 098 859</strong> ou{' '}
                    <strong className="fs-18">(+237) 698 776 757</strong>
                  </p>
                </div>
              </div>
              <div className="col-lg-6 res-991-mt-40 res-991-text-center">
                <div className="position-relative res-991-mt-30">
                  {/* ttm_single_image-wrapper */}

                  <div className="ttm_single_image-wrapper text-sm-center">
                    <ImageLoader
                      className="img-fluid"
                      src="images/about-img.jpeg"
                      title="single-img-one"
                      alt="single-img-one"
                    />
                  </div>
                  {/* ttm_single_image-wrapper end */}
                </div>
                <div className="title-desc mt-20 text-left d-none d-md-block">
                  <p>Nos services sont divers et variés:</p>
                  {/* row */}
                  <div className="row no-gutters mt-4">
                    <div className="col-md-6 col-lg-6 col-sm-6">
                      <ul className="ttm-list ttm-list-style-icon">
                        <li>
                          <i className="far fa-circle ttm-textcolor-skincolor" />
                          <span className="ttm-list-li-content">Développement web </span>
                        </li>
                        <li>
                          <i className="far fa-circle ttm-textcolor-skincolor" />
                          <span className="ttm-list-li-content">Développement de progiciels</span>
                        </li>
                        <li>
                          <i className="far fa-circle ttm-textcolor-skincolor" />
                          <span className="ttm-list-li-content">Design graphique</span>
                        </li>
                      </ul>
                    </div>
                    <div className="col-md-6 col-lg-6 col-sm-6">
                      <ul className="ttm-list ttm-list-style-icon">
                        <li>
                          <i className="far fa-circle ttm-textcolor-skincolor" />
                          <span className="ttm-list-li-content">Développement mobile</span>
                        </li>
                        <li>
                          <i className="far fa-circle ttm-textcolor-skincolor" />
                          <span className="ttm-list-li-content">Robotique mobile </span>
                        </li>
                        <li>
                          <i className="far fa-circle ttm-textcolor-skincolor" />
                          <span className="ttm-list-li-content">Intelligence artificielle</span>
                        </li>
                      </ul>
                    </div>
                  </div>
                  {/* row END*/}
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* aboutus-section end*/}

        {/* progress-bar-section */}
        <section className="ttm-row zero-padding-section res-991-p-0 ttm-bgcolor-white clearfix">
          <div className="container-xl">
            <div className="row no-gutters">
              <div className="col-md-12">
                <div className="ttm-bgcolor-grey ttm-bg ttm-col-bgcolor-yes ttm-left-span spacing-7 position-relative z-1">
                  <div className="ttm-col-wrapper-bg-layer ttm-bg-layer">
                    <div className="ttm-bg-layer-inner" />
                  </div>
                  <div className="layer-content">
                    <div className="row">
                      <div className="col-lg-6">
                        {/* ttm_single_image-wrapper */}
                        <div className="ttm_single_image-wrapper">
                          <ImageLoader
                            className="img-fluid"
                            src="images/bg-about-1.png"
                            alt="experience"
                          />
                        </div>
                        {/* ttm_single_image-wrapper end */}
                        <div className="about-overlay-02">
                          <h3>5 années d'expériences</h3>
                          <p>dans la technologie de pointe</p>
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="res-991-mt-30">
                          {/* section title */}
                          <div className="section-title with-desc clearfix">
                            <div className="title-header">
                              <h2 className="title">
                                Notre <span>vision</span>
                              </h2>
                            </div>
                            <div className="title-desc">
                              <p>
                                Être un acteur incontournable dans le développement logiciel et la
                                robotique mobile au niveau international dans 10 prochaines années
                              </p>
                            </div>
                          </div>

                          <div className="section-title with-desc clearfix">
                            <div className="title-header">
                              <h2 className="title">
                                Notre <span>mission</span>
                              </h2>
                            </div>
                            <div className="title-desc">
                              <p>
                                Développer des outils à la pointe de la technologie pour permettre
                                aux entreprises comme aux particuliers de réaliser des profits et
                                améliorer leur performance.
                              </p>
                            </div>
                          </div>

                          <div className="section-title with-desc clearfix">
                            <div className="title-header">
                              <h2 className="title">
                                Quelques <span>dates clés</span>
                              </h2>
                            </div>
                            <div className="title-desc">
                              <ul>
                                <li>
                                  <b>Novembre 2016</b>: <span>Naissance de House Innovation</span>
                                </li>
                              </ul>
                              <ul>
                                <li>
                                  <b>Avril 2018</b>: <span>Premier client étranger</span>
                                </li>
                              </ul>
                              <ul>
                                <li>
                                  <b>Mars 2022</b>:{' '}
                                  <span>
                                    {' '}
                                    Acquisition d'un nouveau local au Carrefour Lycée de Makepe
                                  </span>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* progress-bar-section end */}

        {/* fid-section */}
        <section className="ttm-row fid-section ttm-bgcolor-darkgrey ttm-bg ttm-bgimage-yes mt_225 res-991-mt-0 clearfix">
          <div className="ttm-row-wrapper-bg-layer ttm-bg-layer" />
          <div className="container-xl">
            {/* row */}
            <div className="row">
              <div className="col-md-3 col-6">
                {/*  ttm-icon */}
                <div className="ttm-fid inside ttm-fid-view-topicon">
                  <div className="ttm-fid-icon-wrapper">
                    <div className="ttm-icon ttm-bgcolor-skincolor ttm-icon_element-size-lg">
                      <i className="flaticon flaticon-developer" />
                      {/*  ttm-icon */}
                    </div>
                  </div>
                  <div className="ttm-fid-contents">
                    {/*  ttm-fid-contents */}
                    <h4>
                      <CountUp start={0} end={97} duration={30} />
                    </h4>
                    <h3 className="ttm-fid-title">Projects</h3>
                    {/*  ttm-fid-title */}
                  </div>
                  {/* ttm-fid-contents end */}
                </div>
              </div>
              <div className="col-md-3 col-6">
                {/*  ttm-icon */}
                <div className="ttm-fid inside ttm-fid-view-topicon">
                  <div className="ttm-fid-icon-wrapper">
                    <div className="ttm-icon ttm-bgcolor-skincolor ttm-icon_element-size-lg">
                      <i className="flaticon flaticon-call" />
                      {/*  ttm-icon */}
                    </div>
                  </div>
                  <div className="ttm-fid-contents">
                    {/*  ttm-fid-contents */}
                    <h4>
                      <CountUp start={0} end={70} duration={30} />
                    </h4>
                    <h3 className="ttm-fid-title">Clients satisfaits</h3>
                    {/*  ttm-fid-title */}
                  </div>
                  {/* ttm-fid-contents end */}
                </div>
              </div>
              <div className="col-md-3 col-6">
                {/*  ttm-icon */}
                <div className="ttm-fid inside ttm-fid-view-topicon">
                  <div className="ttm-fid-icon-wrapper">
                    <div className="ttm-icon ttm-bgcolor-skincolor ttm-icon_element-size-lg">
                      <i className="flaticon flaticon-global" />
                      {/*  ttm-icon */}
                    </div>
                  </div>
                  <div className="ttm-fid-contents">
                    {/*  ttm-fid-contents */}
                    <h4>
                      <CountUp start={0} end={13} duration={10} />
                    </h4>
                    <h3 className="ttm-fid-title">Partenaires, dont 3 à l'international</h3>
                    {/*  ttm-fid-title */}
                  </div>
                  {/* ttm-fid-contents end */}
                </div>
              </div>
              <div className="col-md-3 col-6">
                {/*  ttm-icon */}
                <div className="ttm-fid inside ttm-fid-view-topicon">
                  <div className="ttm-fid-icon-wrapper">
                    <div className="ttm-icon ttm-bgcolor-skincolor ttm-icon_element-size-lg">
                      <i className="flaticon flaticon-global-1" />
                      {/*  ttm-icon */}
                    </div>
                  </div>
                  <div className="ttm-fid-contents">
                    {/*  ttm-fid-contents */}
                    <h4>
                      <CountUp start={0} end={12} duration={10} />
                    </h4>
                    <h3 className="ttm-fid-title">
                      Prix et distinctions, dont 1 à l'international.
                    </h3>
                    {/*  ttm-fid-title */}
                  </div>
                  {/* ttm-fid-contents end */}
                </div>
              </div>
            </div>
            {/* row end*/}
          </div>
        </section>
        {/* fid-section end */}

        {/* our-partner-section */}
        <section className="ttm-row bottomzero-padding-section clearfix">
          <div className="container-xl pb-5 res-991-pb-0">
            <div className="row align-items-center">
              <div className="col-lg-7 res-991-text-center">
                <div className="section-title style3 mb-0 clearfix">
                  <div className="title-header mb-0">
                    <h5>Nous evoluons ensembles</h5>
                    <h2 className="title">
                      Ils nous font <span>confiance</span>
                    </h2>
                  </div>
                </div>
              </div>
              <div className="col-lg-5 res-991-text-center">
                <div className="title-desc">
                  Un projet est une entreprise temporaire dont le début et la fin sont définis et à
                  laquelle des ressources sont affectées.
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="ttm-row our-partner-section mt_90 res-991-mt-0 res-991-p-0 clearfix">
          <div className="container-xl">
            <div className="row">
              <div className="col-12 text-center">
                <Slider
                  className="row slick_slider slick-btn-color ttm-boxes-spacing-30px"
                  {...slick_slider_partners}
                >
                  {clients.map((client) => (
                    <div className="ttm-box-col-wrapper" key={client.name}>
                      <div className="client-box ttm-box-view-boxed-logo">
                        <div className="client">
                          <div
                            className="ttm-client-logo-tooltip ttm-client-logo-box"
                            data-tooltip={client.name}
                          >
                            <ImageLoader src={client.src} className="img-fluid" alt={client.name} />
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </Slider>
              </div>
            </div>
          </div>
        </section>
        {/* our-partner-section end */}
      </div>
    );
  }
}

export default Aboutus;
