import React, { Component } from 'react';
import Sidebar from './ServiceSidebar';
import Header from '../layout/Header';
import ImageLoader from '../../containers/ImageLoader';

export class RobotiqueService extends Component {
  render() {
    return (
      <div className="site-main">
        <Header />

        {/* page-title */}
        <div className="ttm-page-title-row ttm-bgcolor-grey">
          <div className="container-xl">
            <div className="row">
              <div className="col-md-12">
                <div className="title-box text-center">
                  <div className="page-title-heading">
                    <h1 className="title">Robotique & Intelligence Artificielle</h1>
                  </div>
                  {/* /.page-title-captions */}
                  <div className="breadcrumb-wrapper">
                    <span>
                      <a title="Homepage" href={process.env.PUBLIC_URL + '/'}>
                        <i className="ti ti-home" />
                        &nbsp;&nbsp;Accueil
                      </a>
                    </span>
                    <span className="ttm-bread-sep">&nbsp; : : &nbsp;</span>
                    <span>Service</span>
                  </div>
                </div>
              </div>
              {/* /.col-md-12 */}
            </div>
            {/* /.row */}
          </div>
          {/* /.container */}
        </div>

        <div className="sidebar ttm-sidebar ttm-bgcolor-white clearfix">
          <div className="container-xl">
            {/* row */}
            <div className="row">
              <div className="col-lg-9 content-area order-lg-2">
                {/* ttm-service-single-content-are */}
                {/* ttm-service-single-content-are */}
                <div className="ttm-service-single-content-area">
                  {/* section title */}
                  <div className="section-title with-desc text-left clearfix">
                    <div className="title-header">
                      <h5>Pour vous, nous...</h5>
                      <h2 className="title">Automatisons vos process</h2>
                    </div>
                  </div>
                  {/* section title end */}
                  <div className="ttm_single_image-wrapper service mb-35">
                    <ImageLoader className="img-fluid" src="images/svg/ai.svg" alt="ai" />
                  </div>
                  <div className="ttm-service-description">
                    {/* <h4>Une application web c'est quoi ?</h4> */}
                    <p>
                      La robotique et l'intelligence artificielle (IA) sont deux technologies qui
                      sont de plus en plus utilisées dans les entreprises. La robotique désigne
                      l'utilisation de machines pour effectuer des tâches qui étaient auparavant
                      effectuées par des humains. L'IA, quant à elle, désigne la capacité d'une
                      machine à apprendre et à s'adapter à son environnement.
                    </p>

                    <p>
                      La robotique et l'IA peuvent être utilisées ensemble pour améliorer
                      l'efficacité et la précision des processus dans les entreprises. Par exemple,
                      les robots peuvent être utilisés pour effectuer des tâches dangereuses ou
                      répétitives, tandis que l'IA peut être utilisée pour automatiser des tâches
                      complexes ou prendre des décisions.
                    </p>

                    <div>
                      Voici quelques exemples d'applications de la robotique et de l'IA dans les
                      entreprises :
                      <ul className="list-defaut">
                        <li>
                          <strong>Automatisation des tâches</strong> : Les robots peuvent être
                          utilisés pour automatiser une grande variété de tâches, telles que
                          l'assemblage, la palettisation, le soudage, la peinture, etc. Cela permet
                          de libérer les employés pour se concentrer sur des tâches plus
                          stratégiques.
                        </li>
                        <li>
                          <strong>Amélioration de la qualité</strong> : Les robots peuvent être
                          utilisés pour améliorer la qualité des produits et services. Par exemple,
                          les robots peuvent être utilisés pour assembler des produits avec une plus
                          grande précision et pour éviter les erreurs humaines.
                        </li>
                        <li>
                          <strong>Augmentation de la sécurité</strong> : Les robots peuvent être
                          utilisés pour améliorer la sécurité des employés en les protégeant des
                          tâches dangereuses. Par exemple, les robots peuvent être utilisés pour
                          effectuer des tâches telles que le soudage ou le travail à haute
                          température.
                        </li>
                        <li>
                          <strong>Découverte de nouveaux produits et services</strong> : L'IA peut
                          être utilisée pour découvrir de nouveaux produits et services en analysant
                          de grandes quantités de données. Par exemple, l'IA peut être utilisée pour
                          identifier des tendances de consommation ou pour développer de nouveaux
                          médicaments.
                        </li>
                      </ul>
                    </div>

                    <p>
                      La robotique et l'IA sont des technologies qui ont le potentiel de
                      révolutionner de nombreux secteurs d'activité. Les entreprises qui adoptent
                      ces technologies dès aujourd'hui sont en bonne position pour prendre une
                      longueur d'avance sur leurs concurrents.
                    </p>
                  </div>
                </div>
                {/* ttm-service-single-content-are end */}
              </div>
              <div className="col-lg-3 widget-area">
                <Sidebar />
              </div>
            </div>
            {/* row end */}
          </div>
        </div>
      </div>
    );
  }
}

export default RobotiqueService;
