import React from 'react';
import Header from '../layout/Header';
import SingleBlog from './components/SingleBlog';
import { useGetAllBlogs } from './../../hooks/api/useBlogAPI';
import Pagination from '../common/Pagination';

const PAGE_SIZE = 6;

const BlogList = () => {
  const [filteredBlogs, setFilteredBlogs] = React.useState([]);
  const [filteredValue, setFilteredValue] = React.useState('');

  const { data: blogs } = useGetAllBlogs();

  const handleBlogsFilter = (value) => {
    setFilteredValue(value);
    setFilteredBlogs(
      (blogs || []).filter((blog) => (blog?.title || '').toLowerCase().includes(value))
    );
  };

  const [currentPage, setCurrentPage] = React.useState(1);

  const currentData = React.useMemo(() => {
    const firstPageIndex = (currentPage - 1) * PAGE_SIZE;
    const lastPageIndex = firstPageIndex + PAGE_SIZE;
    return filteredBlogs?.length > 0 ? filteredBlogs?.slice(firstPageIndex, lastPageIndex) : [];
  }, [currentPage, filteredBlogs]);

  React.useEffect(() => {
    setFilteredBlogs(blogs);
  }, [blogs]);

  return (
    <div className="site-main">
      <Header />

      {/* page-title */}
      <div className="ttm-page-title-row">
        <div className="container-xl">
          <div className="row">
            <div className="col-md-12">
              <div className="title-box text-center">
                <div className="page-title-heading">
                  <h1 className="title">Nos articles</h1>
                </div>
                {/* /.page-title-captions */}
                <div className="breadcrumb-wrapper">
                  <span>
                    <a title="Homepage" href={process.env.PUBLIC_URL + '/'}>
                      <i className="ti ti-home" />
                      &nbsp;&nbsp;Accueil
                    </a>
                  </span>
                  <span className="ttm-bread-sep">&nbsp; : : &nbsp;</span>
                  <span>Articles</span>
                </div>
              </div>
            </div>
            {/* /.col-md-12 */}
          </div>
          {/* /.row */}
        </div>
        {/* /.container */}
      </div>

      <section className="ttm-row grid-section ttm-bgcolor-grey clearfix">
        <div className="container-xl">
          <div className="row mb-4">
            <div className="col-lg-4">
              <div className="form-group">
                <input
                  type="text"
                  className="form-control form-control-sm bg-white"
                  placeholder="Rechercher par mot clé..."
                  onChange={(e) => handleBlogsFilter(e.target.value)}
                />
              </div>
            </div>
          </div>
          {/* row */}
          <div className="row">
            {Boolean(filteredValue) && (filteredBlogs || []).length > 0 && (
              <div className="col-12">
                <div className="filtered-found mb-3">
                  {(filteredBlogs || []).length} article(s) trouvés
                </div>
              </div>
            )}
            {(currentData || []).map((blog) => (
              <div className="col-lg-4 col-md-6" key={blog?.slug}>
                <SingleBlog blog={blog} />
              </div>
            ))}
            {(filteredBlogs || []).length <= 0 && (
              <div className="col-12">
                {Boolean(filteredValue) ? (
                  <div className="not-found">Aucun d'articles trouvés pour "{filteredValue}"</div>
                ) : (
                  <div className="not-found">Pas d'articles pour le moment, revenez plus tard</div>
                )}
              </div>
            )}
          </div>
          {/* row end */}
          <div className="row">
            <div className="col-md-12 text-center">
              <Pagination
                currentPage={currentPage}
                totalCount={filteredBlogs?.length}
                pageSize={PAGE_SIZE}
                onPageChange={(page) => setCurrentPage(page)}
              />
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default BlogList;
