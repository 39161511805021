import { usePagination, DOTS } from '../../hooks/common/usePagination';
import React from 'react';

const Pagination = ({
  className = '',
  totalCount = 0,
  siblingCount = 1,
  currentPage = 1,
  pageSize = 6,
  onPageChange = (index) => {},
}) => {
  const paginationRange = usePagination({
    currentPage,
    totalCount,
    siblingCount,
    pageSize,
  });

  const isCurrentPage = (check) => {
    if (check) return 'current';
    return '';
  };

  const lastPage = paginationRange[paginationRange.length - 1];

  const onNext = () => {
    if (currentPage !== lastPage) onPageChange(currentPage + 1);
  };

  const onPrevious = () => {
    if (currentPage !== 1) onPageChange(currentPage - 1);
  };

  const renderItem = (pag, index) => {
    if (pag === DOTS) {
      return (
        <span key={index} className="page-numbers">
          &#8230;
        </span>
      );
    }

    return (
      <span
        key={index}
        className={`page-numbers ${isCurrentPage(pag === currentPage)}`}
        onClick={() => onPageChange(pag)}
      >
        {pag}
      </span>
    );
  };

  return (
    totalCount > pageSize && (
      <nav className={`ttm-pagination ${className}`}>
        <span
          className={`previous ${currentPage === 1 ? 'page-numbers-disabled' : 'page-numbers'}`}
          onClick={onPrevious}
        >
          <i className="ti ti-angle-left"></i>
        </span>
        {paginationRange.map(renderItem)}
        <span
          className={`next ${currentPage === lastPage ? 'page-numbers-disabled' : 'page-numbers'}`}
          onClick={onNext}
        >
          <i className="ti ti-angle-right"></i>
        </span>
      </nav>
    )
  );
};

export default Pagination;
