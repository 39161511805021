import * as yup from 'yup';

export const ContactFormSchema = yup
  .object({
    subject: yup.string().required(),
    contact: yup.object({
      name: yup.string().required(),
      email: yup.string().email().required(),
      phone: yup.string().default(undefined),
    }),
    body: yup.string().required(),
  })
  .required();

// {
//   "subject": "Donacien M",
//   "contact": {
//     "name": "Donacien M",
//     "email": "donacienm@ex.com",
//     "phone": "698694738"
//   },
//   "body": "Some content"
// }
