import React from 'react';
import ImageLoader from '../../../containers/ImageLoader';

const months = {
  1: 'Jan',
  2: 'Fer',
  3: 'Mar',
  4: 'Avr',
  5: 'Mai',
  6: 'Jui',
  7: 'Juil',
  8: 'Aou',
  9: 'Sep',
  10: 'Oct',
  11: 'Nov',
  12: 'Dec',
};

const SingleBlog = ({ blog }) => {
  const { slug, image, comment_count, publish_at, title } = blog;

  return (
    <div className="featured-imagebox featured-imagebox-blog style2 mb-30">
      <div className="featured-thumbnail">
        {/* featured-thumbnail */}
        <ImageLoader
          src={image ? process.env.REACT_APP_API_URL + image?.url : ''}
          className="img-fluid"
          alt={title}
        />
        <div className="ttm-blog-overlay-iconbox">
          <a href={process.env.PUBLIC_URL + `/blogs/${slug}`}>
            <i className="ti ti-eye" />
          </a>
        </div>
        <div className="ttm-box-view-overlay" />
      </div>
      <div className="featured-content">
        {/* featured-content */}
        <div className="ttm-box-post-date">
          {/* ttm-box-post-date */}
          <span className="ttm-entry-date">
            <time className="entry-date" dateTime={publish_at}>
              {new Date(publish_at).getDate()}
              <span className="entry-month entry-year">
                {months[new Date(publish_at).getMonth() + 1]}
              </span>
            </time>
          </span>
        </div>
        <div className="featured-title">
          {/* featured-title */}
          <h5>
            <a href={process.env.PUBLIC_URL + `/blogs/${slug}`}>{title}</a>
          </h5>
        </div>
        <div className="post-meta">
          {/* post-meta */}
          <span className="ttm-meta-line">
            <i className="fa fa-comments" />
            {comment_count} commentaires
          </span>
          <span className="ttm-meta-line">
            <i className="fa fa-user" />
            House Innovation
          </span>
        </div>
      </div>
    </div>
  );
};

export default SingleBlog;
