import React from 'react';
import { createBrowserHistory } from 'history';
import { Router, Route } from 'react-router-dom';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

// Layout
import Footer from './components/layout/Footer';
import ScrollToTop from './components/layout/Gotop';

// pages
import Home from './components/Home';

import Aboutus from './components/pages/Aboutus';

import Contact from './components/pages/Contact';

import Team from './components/pages/Team';

import Blog from './components/blog/Blog';
import BlogDetails from './components/blog/BlogDetails';

import Project from './components/project/Project';
import ProjectDetails from './components/project/ProjectDetails';

import WebService from './components/services/WebService';
import MobileService from './components/services/MobileService';
import RobotiqueService from './components/services/RobotiqueService';
import DesignService from './components/services/DesignService';
import ConseilService from './components/services/ConseilService';

const appHistory = {
  basename: process.env.PUBLIC_URL,
};

const history = createBrowserHistory(appHistory);

function App() {
  return (
    <div className="page">
      <Router basename={process.env.PUBLIC_URL} history={history}>
        <ScrollToTop />

        <Route exact path="/" component={Home} />
        <Route exact path="/aboutus" component={Aboutus} />
        <Route exact path="/contact" component={Contact} />
        <Route exact path="/team" component={Team} />
        <Route exact path="/blogs" component={Blog} />
        <Route exact path="/blogs/:slug" component={BlogDetails} />
        <Route exact path="/projects" component={Project} />
        <Route exact path="/projects/:slug" component={ProjectDetails} />

        <Route exact path="/services/web" component={WebService} />
        <Route exact path="/services/mobile" component={MobileService} />
        <Route exact path="/services/robotique" component={RobotiqueService} />
        <Route exact path="/services/design" component={DesignService} />
        <Route exact path="/services/conseil" component={ConseilService} />

        <Footer />
      </Router>
    </div>
  );
}

export default App;
