import React, { Component } from 'react';
import Sidebar from './ServiceSidebar';
import Header from '../layout/Header';
import ImageLoader from '../../containers/ImageLoader';

export class DesignService extends Component {
  render() {
    return (
      <div className="site-main">
        <Header />

        {/* page-title */}
        <div className="ttm-page-title-row ttm-bgcolor-grey">
          <div className="container-xl">
            <div className="row">
              <div className="col-md-12">
                <div className="title-box text-center">
                  <div className="page-title-heading">
                    <h1 className="title">Design graphique</h1>
                  </div>
                  {/* /.page-title-captions */}
                  <div className="breadcrumb-wrapper">
                    <span>
                      <a title="Homepage" href={process.env.PUBLIC_URL + '/'}>
                        <i className="ti ti-home" />
                        &nbsp;&nbsp;Accueil
                      </a>
                    </span>
                    <span className="ttm-bread-sep">&nbsp; : : &nbsp;</span>
                    <span>Service</span>
                  </div>
                </div>
              </div>
              {/* /.col-md-12 */}
            </div>
            {/* /.row */}
          </div>
          {/* /.container */}
        </div>

        <div className="sidebar ttm-sidebar ttm-bgcolor-white clearfix">
          <div className="container-xl">
            {/* row */}
            <div className="row">
              <div className="col-lg-9 content-area order-lg-2">
                {/* ttm-service-single-content-are */}
                {/* ttm-service-single-content-are */}
                <div className="ttm-service-single-content-area">
                  {/* section title */}
                  <div className="section-title with-desc text-left clearfix">
                    <div className="title-header">
                      <h5>Pour vous, nous...</h5>
                      <h2 className="title">Rendons vos idées vivantes</h2>
                    </div>
                  </div>
                  {/* section title end */}
                  <div className="ttm_single_image-wrapper service mb-35">
                    <ImageLoader
                      className="img-fluid"
                      src="images/svg/design.svg"
                      alt="single-img-twelve"
                    />
                  </div>
                  <div className="ttm-service-description">
                    {/* <h4>Une application web c'est quoi ?</h4> */}
                    <p>
                      Le design graphique est l'art et la pratique de la conception de la
                      communication visuelle. Il utilise des éléments visuels tels que la
                      typographie, l'imagerie et la mise en page pour créer des compositions qui
                      transmettent un message ou une idée. Les designers graphiques travaillent dans
                      une variété de contextes, y compris les entreprises, les agences de publicité,
                      les studios de design et les organisations à but non lucratif.
                    </p>

                    <p>
                      La robotique et l'IA peuvent être utilisées ensemble pour améliorer
                      l'efficacité et la précision des processus dans les entreprises. Par exemple,
                      les robots peuvent être utilisés pour effectuer des tâches dangereuses ou
                      répétitives, tandis que l'IA peut être utilisée pour automatiser des tâches
                      complexes ou prendre des décisions.
                    </p>

                    <div>
                      Dans une entreprise, le design graphique peut être utilisé à une variété de
                      fins, notamment :
                      <ul className="list-defaut">
                        <li>
                          Créer une identité visuelle pour l'entreprise, y compris un logo, une
                          charte graphique et des éléments d'impression
                        </li>
                        <li>
                          Concevoir des supports marketing et promotionnels, tels que des affiches,
                          des brochures, des dépliants et des sites Web
                        </li>
                        <li>
                          Créer des supports de communication interne, tels que des intranets, des
                          newsletters et des rapports
                        </li>
                        <li>
                          Concevoir des supports de vente, tels que des présentations, des brochures
                          et des catalogues
                        </li>
                        <li>
                          Créer des supports de formation, tels que des manuels, des guides et des
                          modules d'apprentissage en ligne
                        </li>
                      </ul>
                    </div>

                    <p>
                      Le designer graphique joue un rôle important dans la communication de la
                      marque de l'entreprise et dans la création d'un impact visuel. Il travaille en
                      étroite collaboration avec les autres départements de l'entreprise, tels que
                      les ventes, le marketing et la communication, pour créer des supports visuels
                      qui sont efficaces et attrayants.
                    </p>

                    <div>
                      Voici quelques-uns des avantages du design graphique pour les entreprises :
                      <ul className="list-defaut">
                        <li>Améliore la notoriété de la marque</li>
                        <li>Augmente les ventes</li>
                        <li>Attire de nouveaux clients</li>
                        <li>Fidèlise les clients existants</li>
                        <li>Crée une image professionnelle</li>
                        <li>Donne une identité visuelle à l'entreprise</li>
                        <li>Facilite la communication</li>
                        <li>Rend les supports visuels plus attrayants</li>
                      </ul>
                    </div>

                    <p>
                      Si vous êtes une entreprise, le design graphique peut être un outil précieux
                      pour vous aider à atteindre vos objectifs. En travaillant avec un designer
                      graphique, vous pouvez créer des supports visuels qui sont efficaces,
                      attrayants et qui correspondent à votre identité visuelle.
                    </p>
                  </div>
                </div>
                {/* ttm-service-single-content-are end */}
              </div>
              <div className="col-lg-3 widget-area">
                <Sidebar />
              </div>
            </div>
            {/* row end */}
          </div>
        </div>
      </div>
    );
  }
}

export default DesignService;
