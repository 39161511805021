import React from 'react';
import Header from '../layout/Header';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { ContactFormSchema } from './../../core/validators/contact.validator';
import { useSendMessage } from '../../hooks/api/useContactAPI';

import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

const Alert = withReactContent(Swal);

const Contact = () => {
  const {
    register,
    formState: { errors },
    handleSubmit: handleSubmitMessage,
    reset: resetContactForm,
  } = useForm({
    resolver: yupResolver(ContactFormSchema),
    defaultValues: {
      save: true,
    },
  });

  const { mutateAsync: sendMessage, isLoading: isSendingMessage } = useSendMessage();

  const submitMessage = (data) =>
    sendMessage(data)
      .then(() => {
        resetContactForm();
        Alert.fire({
          title: 'Courrier envoyé',
          text: 'Merci pour votre message, nous vous répondrons rapidement si nécessaire.',
          icon: 'success',
          confirmButtonText: 'Okay',
          customClass: {
            confirmButton: 'ttm-btn ttm-btn-size-md ttm-bgcolor-skincolor',
          },
        });
      })
      .catch(() => {
        Alert.fire({
          title: 'Error!',
          text: "Impossible d'envoyer le courrier, veuillez réessayer plus tard",
          icon: 'error',
          confirmButtonText: 'Okay',
          customClass: {
            confirmButton: 'ttm-btn ttm-btn-size-md ttm-bgcolor-skincolor',
          },
        });
      });

  return (
    <div className="site-main">
      <Header />

      {/* page-title */}
      <div className="ttm-page-title-row">
        <div className="container-xl">
          <div className="row">
            <div className="col-md-12">
              <div className="title-box text-center">
                <div className="page-title-heading">
                  <h1 className="title">Contactez-nous</h1>
                </div>
                {/* /.page-title-captions */}
                <div className="breadcrumb-wrapper">
                  <span>
                    <a title="Homepage" href={process.env.PUBLIC_URL + '/'}>
                      <i className="ti ti-home" />
                      &nbsp;&nbsp;Accueil
                    </a>
                  </span>
                  <span className="ttm-bread-sep">&nbsp; : : &nbsp;</span>
                  <span>Contact</span>
                </div>
              </div>
            </div>
            {/* /.col-md-12 */}
          </div>
          {/* /.row */}
        </div>
        {/* /.container */}
      </div>

      <section className="ttm-row zero-padding-section clearfix">
        <div className="container-xl">
          <div className="row no-gutters">
            {/* row */}
            <div className="col-lg-5">
              <div className="spacing-9">
                {/* section title */}
                <div className="section-title with-desc clearfix">
                  <div className="title-header">
                    <h5>Passez nous voir dans nos locaux!</h5>
                    <h2 className="title">Coordonnées</h2>
                  </div>
                </div>
                {/* section title end */}

                <div className="row">
                  <div className="col-12">
                    {/* featured-icon-box */}
                    <div className="featured-icon-box style2 left-icon icon-align-top">
                      <div className="featured-icon">
                        {/* featured-icon */}
                        <div className="ttm-icon ttm-bgcolor-skincolor ttm-icon_element-size-md rounded-circle">
                          <i className="ti-location-pin" />
                        </div>
                      </div>
                      <div className="featured-content">
                        <div className="featured-title">
                          {/* featured title */}
                          <h5>Adresse</h5>
                        </div>
                        <div className="featured-desc">
                          {/* featured desc */}
                          <p>Carrefour Lycée Makepe, Douala, Cameroun</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                {/* separator */}
                <div className="separator">
                  <div className="sep-line mt-4 mb-4" />
                </div>
                {/* separator */}

                <div className="row">
                  <div className="col-12">
                    {/* featured-icon-box */}
                    <div className="featured-icon-box style2 left-icon icon-align-top">
                      <div className="featured-icon">
                        {/* featured-icon */}
                        <div className="ttm-icon ttm-bgcolor-skincolor ttm-icon_element-size-md rounded-circle">
                          <i className="fa fa-phone" />
                        </div>
                      </div>
                      <div className="featured-content">
                        <div className="featured-title">
                          {/* featured title */}
                          <h5>Téléphone</h5>
                        </div>
                        <div className="featured-desc">
                          {/* featured desc */}
                          <p>+237 697 098 859</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                {/* separator */}
                <div className="separator">
                  <div className="sep-line mt-4 mb-4" />
                </div>
                {/* separator */}

                <div className="row">
                  <div className="col-12">
                    {/* featured-icon-box */}
                    <div className="featured-icon-box style2 left-icon icon-align-top">
                      <div className="featured-icon">
                        {/* featured-icon */}
                        <div className="ttm-icon ttm-bgcolor-skincolor ttm-icon_element-size-md rounded-circle">
                          <i className="ti ti-email" />
                        </div>
                      </div>
                      <div className="featured-content">
                        <div className="featured-title">
                          {/* featured title */}
                          <h5>Email</h5>
                        </div>
                        <div className="featured-desc">
                          {/* featured desc */}
                          <p>info@houseinnovationgroup.com</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-lg-7" id="message">
              <div className="spacing-10 ttm-bgcolor-grey ttm-bg ttm-col-bgcolor-yes ttm-right-span">
                <div className="ttm-col-wrapper-bg-layer ttm-bg-layer">
                  <div className="ttm-bg-layer-inner" />
                </div>

                {/* section title */}
                <div className="section-title with-desc clearfix">
                  <div className="title-header">
                    <h5>Laissez un message</h5>
                    <h2 className="title">Qu'avez-vous à dire?</h2>
                  </div>
                </div>
                {/* section title end */}

                <form
                  id="ttm-quote-form"
                  className="row ttm-quote-form clearfix"
                  onSubmit={handleSubmitMessage(submitMessage)}
                >
                  <div className="col-sm-6 col-md-6">
                    <div className="form-group">
                      <input
                        type="text"
                        className={`form-control bg-white ${errors.contact?.name ? 'invalid' : ''}`}
                        placeholder="Nom complet*"
                        {...register('contact.name')}
                      />
                    </div>
                  </div>

                  <div className="col-sm-6 col-md-6">
                    <div className="form-group">
                      <input
                        type="email"
                        className={`form-control bg-white ${
                          errors.contact?.email ? 'invalid' : ''
                        }`}
                        placeholder="Adresse email*"
                        {...register('contact.email')}
                      />
                    </div>
                  </div>

                  <div className="col-sm-6 col-md-6">
                    <div className="form-group">
                      <input
                        type="tel"
                        className={`form-control bg-white ${
                          errors.contact?.phone ? 'invalid' : ''
                        }`}
                        placeholder="Numéro de téléphone"
                        {...register('contact.phone')}
                      />
                    </div>
                  </div>

                  <div className="col-sm-6 col-md-6">
                    <div className="form-group">
                      <input
                        type="text"
                        className={`form-control bg-white ${errors.subject ? 'invalid' : ''}`}
                        placeholder="Sujet*"
                        {...register('subject')}
                      />
                    </div>
                  </div>
                  <div className="col-sm-12 col-md-12">
                    <div className="form-group">
                      <textarea
                        rows={6}
                        placeholder="Votre message ici..."
                        className={`form-control bg-white ${errors.subject ? 'invalid' : ''}`}
                        {...register('body')}
                      />
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="text-right">
                      <button
                        type="submit"
                        className="mt-3 ttm-btn ttm-btn-size-md ttm-bgcolor-skincolor"
                        disabled={isSendingMessage}
                      >
                        <span
                          className={`fa fa-spinner fa-spin mr-2 ${
                            isSendingMessage ? 'd-inline' : 'd-none'
                          }`}
                        ></span>
                        Envoyer
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
          {/* row end */}
        </div>
      </section>

      <section className="ttm-row map-section res-991-p-0 clearfix">
        <div className="map-wrapper">
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d994.9231838639105!2d9.7563164!3d4.0828462!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x10610d86b9d8c8e3%3A0x296b1b06f6e0b30a!2sHOUSE%20INNOVATION%20SARL!5e0!3m2!1sfr!2scm!4v1690386004167!5m2!1sfr!2scm"
            title="House Innovation mark"
            height="100%"
            allowFullScreen=""
            loading="lazy"
            referrerPolicy="no-referrer-when-downgrade"
          />
        </div>
      </section>
    </div>
  );
};

export default Contact;
