import React from 'react';
import Blogsidebar from './BlogSidebar';
import Header from '../layout/Header';
import { useParams } from 'react-router-dom';
import { useGetBlog, useSaveBlogComment } from './../../hooks/api/useBlogAPI';
import ImageLoader from '../../containers/ImageLoader';
import { longDate, longDateTime } from '../../core/utils/date.util';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { CommentFormSchema } from '../../core/validators/comment.validator';
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from 'react-accessible-accordion';

import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

const Alert = withReactContent(Swal);

const BlogDetails = () => {
  const { slug } = useParams();
  const {
    register,
    formState: { errors },
    handleSubmit: handleSubmitComment,
    reset: resetCommentForm,
  } = useForm({
    resolver: yupResolver(CommentFormSchema),
    defaultValues: {
      save: true,
    },
  });

  const { mutateAsync: saveComment, isLoading: isSavingComment } = useSaveBlogComment(slug);

  const { data: blog, refetch: refetchBlog } = useGetBlog(slug);

  const submitComment = (data) =>
    saveComment({
      author: data.author,
      email: data.email || undefined,
      content: data.content,
    })
      .then(() => {
        refetchBlog();
        resetCommentForm();
        Alert.fire({
          title: 'Commentaire enregistré',
          icon: 'success',
          confirmButtonText: 'Okay',
          customClass: {
            confirmButton: 'ttm-btn ttm-btn-size-md ttm-bgcolor-skincolor',
          },
        });
      })
      .catch(() => {
        Alert.fire({
          title: 'Error!',
          text: "Impossible d'enregistrer votre commentaire, veuillez réessayer plus tard",
          icon: 'error',
          confirmButtonText: 'Okay',
          customClass: {
            confirmButton: 'ttm-btn ttm-btn-size-md ttm-bgcolor-skincolor',
          },
        });
      });

  return (
    <div className="site-main">
      <Header />

      {/* page-title */}
      <div className="ttm-page-title-row">
        <div className="container-xl">
          <div className="row">
            <div className="col-md-12">
              <div className="title-box text-center">
                <div className="page-title-heading">
                  <h1 className="title">{blog?.title}</h1>
                </div>
                {/* /.page-title-captions */}
                <div className="breadcrumb-wrapper">
                  <span>
                    <a title="Homepage" href={process.env.PUBLIC_URL + '/'}>
                      <i className="ti ti-home" />
                      &nbsp;&nbsp;Accueil
                    </a>
                  </span>
                  <span className="ttm-bread-sep">&nbsp; : : &nbsp;</span>
                  <span>
                    <a title="Homepage" href={process.env.PUBLIC_URL + '/blogs'}>
                      &nbsp;&nbsp;Articles
                    </a>
                  </span>
                  <span className="ttm-bread-sep">&nbsp; : : &nbsp;</span>
                  <span>Details</span>
                </div>
              </div>
            </div>
            {/* /.col-md-12 */}
          </div>
          {/* /.row */}
        </div>
        {/* /.container */}
      </div>

      <div className="sidebar ttm-bgcolor-white clearfix">
        <div className="container-xl">
          {/* row */}
          <div className="row">
            <div className="col-lg-9 content-area">
              {/* ttm-service-single-content-are */}
              <article className="post ttm-blog-single clearfix">
                {/* post-featured-wrapper */}
                {blog?.image && (
                  <div className="ttm-post-featured-wrapper">
                    <div className="ttm-post-featured">
                      <ImageLoader
                        src={process.env.REACT_APP_API_URL + blog?.image?.url}
                        className="img-fluid"
                        alt={blog?.title}
                      />
                    </div>
                  </div>
                )}
                {/* post-featured-wrapper end */}

                {/* ttm-blog-classic-content */}
                <div className="ttm-blog-single-content">
                  <div className="entry-content">
                    <div className="ttm-post-entry-header">
                      <div className="post-meta">
                        <span className="ttm-meta-line byline">
                          <i className="fa fa-user" />
                          Par House Innovation
                        </span>
                        <span className="ttm-meta-line entry-date">
                          <i className="fa fa-calendar" />
                          <time
                            className="entry-date published"
                            dateTime="2018-07-28T00:39:29+00:00"
                          >
                            {longDate(blog?.publish_at)}
                          </time>
                        </span>
                        <span className="ttm-meta-line tags-links">
                          <i className="far fa-comment" />
                          {(blog?.comments || []).length}
                        </span>
                      </div>
                    </div>

                    {/* separator */}
                    <div className="separator">
                      <div className="sep-line mt-3 mb-4" />
                    </div>
                    {/* separator */}

                    <p>{blog?.description}</p>

                    <div
                      className="article-content"
                      dangerouslySetInnerHTML={{ __html: blog?.content }}
                    />

                    {/* separator */}
                    <div className="separator">
                      <div className="sep-line mt-4 mb-4" />
                    </div>
                    {/* separator */}

                    <div className="clearfix">
                      <div className="ttm-tag-list float-md-left">
                        <span>
                          {(blog?.tags || []).map((tag) => (
                            <a key={tag} href={process.env.PUBLIC_URL + `/blogs?tag=${tag}`}>
                              {tag}
                            </a>
                          ))}
                        </span>
                      </div>
                    </div>

                    <div className="ttm-blog-classic-box-comment">
                      <div id="comments" className="comments-area">
                        <Accordion
                          allowMultipleExpanded
                          allowZeroExpanded
                          className="accordion style2"
                        >
                          <AccordionItem>
                            <AccordionItemHeading aria-level="2">
                              <AccordionItemButton>
                                <span className="comments-title">
                                  {(blog?.comments || []).length} commentaires sur “{blog?.title}”
                                </span>
                              </AccordionItemButton>
                            </AccordionItemHeading>
                            <AccordionItemPanel>
                              <ol className="comment-list">
                                {(blog?.comments || []).map((comment) => (
                                  <li key={comment.id}>
                                    <div className="comment-body">
                                      <div className="comment-author vcard">
                                        <img
                                          src="https://via.placeholder.com/100X100/444444.jpg"
                                          className="avatar"
                                          alt="comment-img"
                                        />
                                      </div>
                                      <div className="comment-box">
                                        <div className="comment-meta commentmetadata">
                                          <h5 className="ttm-comment-owner">{comment.author}</h5>
                                          {longDateTime(comment.created_at)}
                                        </div>
                                        <div className="author-content-wrap">
                                          <p>{comment.content}</p>
                                        </div>
                                      </div>
                                    </div>
                                  </li>
                                ))}
                              </ol>
                            </AccordionItemPanel>
                          </AccordionItem>
                        </Accordion>

                        <div className="comment-respond">
                          <h3 className="comment-reply-title">Laisser un commentaire</h3>
                          <p className="comment-notes">Votre adresse e-mail ne sera pas publiée.</p>

                          <form
                            id="ttm-quote-form"
                            className="row ttm-quote-form ttm-bgcolor-grey clearfix pt-4"
                            onSubmit={handleSubmitComment(submitComment)}
                          >
                            <div className="col-sm-12 col-md-6">
                              <div className="form-group">
                                <input
                                  type="text"
                                  className={`form-control bg-white ${
                                    errors.author ? 'invalid' : ''
                                  }`}
                                  placeholder="Nom complet"
                                  {...register('author')}
                                />
                              </div>
                            </div>

                            <div className="col-sm-12 col-md-6">
                              <div className="form-group">
                                <input
                                  type="email"
                                  className={`form-control bg-white ${
                                    errors.email ? 'invalid' : ''
                                  }`}
                                  placeholder="Email"
                                  {...register('email')}
                                />
                              </div>
                            </div>

                            <div className="col-sm-12 col-md-12">
                              <div className="form-group">
                                <textarea
                                  rows={5}
                                  placeholder="Commentaire..."
                                  className={`form-control bg-white ${
                                    errors.content ? 'invalid' : ''
                                  }`}
                                  {...register('content')}
                                />
                              </div>
                            </div>

                            <div className="col-md-12 mb-2 d-flex align-items-start">
                              <input
                                id="comment-cookies-consent"
                                type="checkbox"
                                className="mt-1"
                                {...register('save')}
                              />
                              &nbsp; &nbsp;
                              <label htmlFor="comment-cookies-consent">
                                Enregistrer mon nom et mon adresse électronique dans ce navigateur
                                pour la prochaine fois que je ferai un commentaire.
                              </label>
                            </div>

                            <div className="col-md-12">
                              <div className="form-group text-right mt-4">
                                <button
                                  type="submit"
                                  className="ttm-btn ttm-btn-size-md ttm-bgcolor-darkgrey"
                                  disabled={isSavingComment}
                                >
                                  {/* {isSavingComment && (
                                    <span className="fa fa-spinner fa-spin mr-2"></span>
                                  )} */}
                                  <span
                                    className={`fa fa-spinner fa-spin mr-2 ${
                                      isSavingComment ? 'd-inline' : 'd-none'
                                    }`}
                                  ></span>
                                  Publier votre commentaire
                                </button>
                              </div>
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* ttm-blog-classic-content end */}
              </article>
              {/* ttm-service-single-content-are end */}
            </div>

            <div className="col-lg-3 widget-area">{blog && <Blogsidebar blog={blog} />}</div>
          </div>
          {/* row end */}
        </div>
      </div>
    </div>
  );
};

export default BlogDetails;
