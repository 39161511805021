export const shortMonths = {
  1: 'Jan',
  2: 'Fev',
  3: 'Mar',
  4: 'Avr',
  5: 'Mai',
  6: 'Jui',
  7: 'Juil',
  8: 'Aou',
  9: 'Sep',
  10: 'Oct',
  11: 'Nov',
  12: 'Dec',
};

export const longMonths = {
  1: 'Janvier',
  2: 'Fevrier',
  3: 'Mars',
  4: 'Avril',
  5: 'Mai',
  6: 'Juin',
  7: 'Juillet',
  8: 'Aout',
  9: 'Septembre',
  10: 'Octobre',
  11: 'Novembre',
  12: 'Decembre',
};

export const longDate = (date) => {
  if (!Boolean(date)) return '';

  const converted = new Date(date);
  return `${converted.getDate()} ${
    longMonths[converted.getMonth() + 1]
  } ${converted.getFullYear()}`;
};

export const longDateTime = (date) => {
  if (!Boolean(date)) return '';

  const converted = new Date(date);
  return `${longDate(date)} à ${converted.getHours()}:${converted.getMinutes()}`;
};
