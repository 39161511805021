import React from 'react';
import Slider from 'react-slick';
import Header from './layout/Header';
import { CarouselPage } from './banner/HomeBanner';

import clients from '../core/data/clients.json';
import partners from '../core/data/partners.json';
import medias from '../core/data/medias.json';
import team from '../core/data/team.json';
import projects from '../core/data/projects.json';
import services from '../core/data/services.json';

import ImageLoader from '../containers/ImageLoader';

import { useGetAllBlogs } from './../hooks/api/useBlogAPI';
import SingleBlog from './blog/components/SingleBlog';

const Home = () => {
  const slick_slider_partners = {
    dots: false,
    arrow: false,
    autoplay: true,
    infinite: true,
    speed: 700,
    slidesToShow: 4,
    slidesToScroll: 1,

    responsive: [
      {
        breakpoint: 1199,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
        },
      },
      {
        breakpoint: 680,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 575,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const slick_slider_services = {
    dots: false,
    arrow: false,
    autoplay: true,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,

    responsive: [
      {
        breakpoint: 1199,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
        },
      },
      {
        breakpoint: 680,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 575,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const slick_slider_clients = {
    dots: false,
    arrow: false,
    autoplay: true,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,

    responsive: [
      {
        breakpoint: 1199,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
        },
      },
      {
        breakpoint: 680,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 575,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const slick_slider_team = {
    dots: false,
    arrow: false,
    autoplay: true,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,

    responsive: [
      {
        breakpoint: 1199,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
        },
      },
      {
        breakpoint: 680,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 575,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const slick_slider_medias = {
    dots: false,
    arrow: false,
    autoplay: true,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,

    responsive: [
      {
        breakpoint: 1199,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const { data: blogs } = useGetAllBlogs(3);

  return (
    <div className="site-main">
      <Header />

      {/* home banner */}
      <CarouselPage />
      {/* home banner end*/}

      {/* quality-section */}
      <section className="ttm-row service-section position-relative z-1 clearfix">
        <div className="container-xl">
          <div className="row no-gutters featured-icon-box-style1-row ttm-bgcolor-darkgrey">
            <div className="col-md-4">
              {/*  featured-icon-box */}
              <div className="featured-icon-box left-icon style1 ttm-bgcolor-skincolor ttm-textcolor-white">
                <div className="featured-icon">
                  {/*  featured-icon */}
                  <div className="ttm-icon ttm-bgcolor-darkgrey ttm-icon_element-size-md">
                    <i className="flaticon flaticon-developer" />
                    {/*  ttm-icon */}
                  </div>
                </div>
                <div className="featured-content">
                  {/*  featured-content */}
                  <div className="featured-title">
                    {/*  featured-title */}
                    <h5>Expertise technique</h5>
                  </div>
                  <div className="featured-desc">
                    {/*  featured-desc */}
                    <p>À la pointe de la technologie</p>
                  </div>
                </div>
              </div>
              {/*  featured-icon-box END */}
            </div>

            <div className="col-md-4">
              {/*  featured-icon-box */}
              <div className="featured-icon-box left-icon style1 ttm-bgcolor-darkgrey ttm-textcolor-white">
                <div className="featured-icon">
                  {/*  featured-icon */}
                  <div className="ttm-icon ttm-bgcolor-skincolor ttm-icon_element-size-md">
                    <i className="flaticon flaticon-clock" />
                    {/*  ttm-icon */}
                  </div>
                </div>
                <div className="featured-content">
                  {/*  featured-content */}
                  <div className="featured-title">
                    {/*  featured-title */}
                    <h5>Expérience personnalisée</h5>
                  </div>
                  <div className="featured-desc">
                    {/*  featured-desc */}
                    <p>Chaque projet est unique</p>
                  </div>
                </div>
              </div>
              {/*  featured-icon-box END */}
            </div>

            <div className="col-md-4">
              <div className="featured-icon-box left-icon style1 ttm-bgcolor-skincolor ttm-textcolor-white h-100">
                <div className="featured-icon">
                  {/*  featured-icon */}
                  <div className="ttm-icon ttm-bgcolor-darkgrey ttm-icon_element-size-md">
                    <i className="flaticon flaticon-system" />
                    {/*  ttm-icon */}
                  </div>
                </div>
                <div className="featured-content">
                  {/*  featured-content */}
                  <div className="featured-title">
                    {/*  featured-title */}
                    <h5>Service de qualité</h5>
                  </div>
                  <div className="featured-desc">
                    {/*  featured-desc */}
                    <p>Clients satisfaits à l'international</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* quality-section end */}

      {/* aboutus-section */}
      <section className="ttm-row aboutus-section clearfix">
        <div className="container-xl">
          <div className="row">
            <div className="col-lg-6">
              {/* section title */}
              <div className="section-title with-desc clearfix">
                <div className="title-header">
                  <h5>Bienvenue chez HOUSE INNOVATION</h5>
                  <h2 className="title">
                    Qui sommes <span>nous</span> ?
                  </h2>
                </div>
                <div className="title-desc">
                  <p>
                    House Innovation SARL née officiellement en 2018, est une Entreprise spécialisée
                    dans l’innovation technologique et le NTIC qui propose une offre adaptée aux
                    besoins du clients, soit une équipe d’ingénieurs qualifiés, un service
                    après-vente de qualité, une maintenance de développement des logiciels adéquats.
                    En effet, L’Entreprise a des atouts sur la capacité de s’adapter au marché, mais
                    aussi bouleverse les codes, soucieux de changer le monde est entourée de
                    meilleurs profils qui travaille et coopère ensemble ainsi pour prôner
                    l’excellence, la collaboration et les risques qui en découlent afin de maximiser
                    le contentement du client qui est ROI.
                  </p>
                  <p>
                    Notre mission pricipale est de développer des outils à la pointe de la
                    technologie pour permettre aux entreprises comme aux particuliers et
                    professionnels de réaliser des profits et améliorer leur performance.
                  </p>
                </div>

                <div className="title-desc mt-20 text-left d-block d-md-none">
                  <p>Nos services sont divers et variés:</p>
                  {/* row */}
                  <div className="row no-gutters mt-4">
                    <div className="col-md-6 col-lg-6 col-sm-6">
                      <ul className="ttm-list ttm-list-style-icon">
                        <li>
                          <i className="far fa-circle ttm-textcolor-skincolor" />
                          <span className="ttm-list-li-content">Développement web </span>
                        </li>
                        <li>
                          <i className="far fa-circle ttm-textcolor-skincolor" />
                          <span className="ttm-list-li-content">Développement de progiciels</span>
                        </li>
                        <li>
                          <i className="far fa-circle ttm-textcolor-skincolor" />
                          <span className="ttm-list-li-content">Design graphique</span>
                        </li>
                      </ul>
                    </div>
                    <div className="col-md-6 col-lg-6 col-sm-6">
                      <ul className="ttm-list ttm-list-style-icon">
                        <li>
                          <i className="far fa-circle ttm-textcolor-skincolor" />
                          <span className="ttm-list-li-content">Développement mobile</span>
                        </li>
                        <li>
                          <i className="far fa-circle ttm-textcolor-skincolor" />
                          <span className="ttm-list-li-content">Robotique mobile </span>
                        </li>
                        <li>
                          <i className="far fa-circle ttm-textcolor-skincolor" />
                          <span className="ttm-list-li-content">Intelligence artificielle</span>
                        </li>
                      </ul>
                    </div>
                  </div>
                  {/* row END*/}
                </div>
              </div>
              {/* section title end */}

              {/* separator */}
              <div className="separator">
                <div className="sep-line mt-4 mb-4" />
              </div>
              {/* separator */}
              <div className="fs-16 ttm-textcolor-darkgrey">
                <span>
                  Appelez pour{' '}
                  <a
                    href={process.env.PUBLIC_URL + '/contact'}
                    className="fw-500 ttm-textcolor-darkgrey"
                  >
                    <u>en savoir plus</u>
                  </a>
                </span>
                <p>
                  <strong className="fs-18">(+237) 697 098 859</strong> ou{' '}
                  <strong className="fs-18">(+237) 698 776 757</strong>
                </p>
              </div>
            </div>
            <div className="col-lg-6 res-991-mt-40 res-991-text-center">
              <div className="position-relative res-991-mt-30">
                {/* ttm_single_image-wrapper */}

                <div className="ttm_single_image-wrapper text-sm-center">
                  <ImageLoader
                    className="img-fluid"
                    src="images/about-img.jpeg"
                    title="single-img-one"
                    alt="single-img-one"
                  />
                </div>
                {/* ttm_single_image-wrapper end */}
              </div>
              <div className="title-desc mt-20 text-left d-none d-md-block">
                <p>Nos services sont divers et variés:</p>
                {/* row */}
                <div className="row no-gutters mt-4">
                  <div className="col-md-6 col-lg-6 col-sm-6">
                    <ul className="ttm-list ttm-list-style-icon">
                      <li>
                        <i className="far fa-circle ttm-textcolor-skincolor" />
                        <span className="ttm-list-li-content">Développement web </span>
                      </li>
                      <li>
                        <i className="far fa-circle ttm-textcolor-skincolor" />
                        <span className="ttm-list-li-content">Développement de progiciels</span>
                      </li>
                      <li>
                        <i className="far fa-circle ttm-textcolor-skincolor" />
                        <span className="ttm-list-li-content">Design graphique</span>
                      </li>
                    </ul>
                  </div>
                  <div className="col-md-6 col-lg-6 col-sm-6">
                    <ul className="ttm-list ttm-list-style-icon">
                      <li>
                        <i className="far fa-circle ttm-textcolor-skincolor" />
                        <span className="ttm-list-li-content">Développement mobile</span>
                      </li>
                      <li>
                        <i className="far fa-circle ttm-textcolor-skincolor" />
                        <span className="ttm-list-li-content">Robotique mobile </span>
                      </li>
                      <li>
                        <i className="far fa-circle ttm-textcolor-skincolor" />
                        <span className="ttm-list-li-content">Intelligence artificielle</span>
                      </li>
                    </ul>
                  </div>
                </div>
                {/* row END*/}
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* aboutus-section end */}

      {/* service-section */}
      <section className="ttm-row history-section bg-img3 ttm-bgcolor-grey ttm-bg ttm-bgimage-yes clearfix">
        <div className="ttm-row-wrapper-bg-layer ttm-bg-layer" />
        <div className="container-xl">
          <div className="row">
            <div className="col-lg-8 offset-lg-2">
              {/* section title */}
              <div className="section-title with-desc text-center clearfix">
                <div className="title-header">
                  <h5>Nos services</h5>
                  <h2 className="title">
                    Nous proposons des services touchant à sensiblement{' '}
                    <span> tous les domaines du digital</span>
                  </h2>
                </div>
              </div>
              {/* section title end */}
            </div>
          </div>

          <Slider className="row slick_slider ttm-boxes-spacing-30px" {...slick_slider_services}>
            {services.map((service) => (
              <div className="ttm-box-col-wrapper" key={service.name}>
                <div className="ttm-history-box-wrapper">
                  {/* ttm-history-box-wrapper  */}
                  <div className="ttm-history-box-icon-wrapper">
                    {/* ttm-history-box-icon-wrapper  */}
                    {/*  featured-icon-box */}
                    <div className="featured-icon-box">
                      <div className="featured-icon">
                        {/*  featured-icon */}
                        <div className="ttm-icon ttm-bgcolor-white ttm-icon_element-size-md">
                          <i className={`flaticon ${service.icon}`} />
                          {/*  ttm-icon */}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="ttm-history-box-border" />
                  <div className="ttm-history-box-details ttm-bgcolor-white">
                    <div className="ttm-historybox-title">
                      <h5>{service.name}</h5>
                    </div>
                    <div className="ttm-historybox-description">{service.description}</div>
                    <a
                      href={process.env.PUBLIC_URL + service.route}
                      className="ttm-btn ttm-btn-size-sm ttm-btn-color-skincolor btn-inline ttm-icon-btn-right mt-20"
                    >
                      En savoir plus <i className="ti ti-angle-double-right" />
                    </a>
                  </div>
                </div>
              </div>
            ))}
          </Slider>

          <div className="row">
            <div className="col-md-12 text-center mt-5 res-991-mt-30">
              <p className="mb-0">
                N'hésitez pas à{' '}
                <strong>
                  <u>
                    <a className="ttm-textcolor-darkgrey" href="/contact">
                      nous contacter
                    </a>
                  </u>
                </strong>{' '}
                en cas de question.
              </p>
            </div>
          </div>
        </div>
      </section>
      {/* service-section end*/}

      {/* whyus-section */}
      <section className="ttm-row zero-padding-section mt_80 res-991-mt-0 clearfix">
        <div className="container-xl">
          <div className="row no-gutters">
            <div className="col-lg-6">
              {/* col-bg-img-one */}
              <div className="col-bg-img-one ttm-bg ttm-col-bgimage-yes ttm-left-span res-991-h-auto">
                <div className="ttm-col-wrapper-bg-layer ttm-bg-layer">
                  <div className="ttm-bg-layer-inner" />
                </div>
              </div>

              {/* Responsive View image */}
              {/* <img
                src="images/why-question-mark-wider.jpg"
                className="ttm-equal-height-image img-fluid"
                alt="col-bgimage-1"
              /> */}
            </div>
            <div className="col-lg-6 z-9">
              <div className="ttm-bgcolor-skincolor ttm-textcolor-white position-relative ttm-right-span">
                <div className="spacing-custom-1 d-none d-xl-block">
                  {/* section title */}
                  <div className="section-title with-desc clearfix">
                    <div className="title-header">
                      <h5>Pourquoi choisir House Innovation</h5>
                      <h2 className="title">
                        Nous vous accompagnons de bout en bout dans la{' '}
                        <span>digitalisation de votre structure</span>
                      </h2>
                    </div>
                    <div className="title-desc">
                      <p></p>
                    </div>
                  </div>
                  {/* section title end */}
                  <div className="row mt-40">
                    <div className="col-12">
                      {/* featured-icon-box */}
                      <div className="featured-icon-box style2 left-icon icon-align-top">
                        <div className="featured-icon">
                          {/* featured-icon */}
                          <div className="ttm-icon ttm-icon_element-border ttm-icon_element-color-white ttm-icon_element-size-md">
                            <i className="flaticon flaticon-web" />
                          </div>
                        </div>
                        <div className="featured-content">
                          <div className="featured-title">
                            {/* featured title */}
                            <h5>Analyse & Conception</h5>
                          </div>
                          <div className="featured-desc">
                            {/* featured desc */}
                            <p>
                              Nous travaillons en étroite collaboration avec vous pour comprendre
                              vos besoins spécifiques en matière de digitalisation. Nous analysons
                              votre structure et vos processus métiers, puis concevons une solution
                              sur mesure qui répond à vos attentes.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* separator */}
                  <div className="separator">
                    <div className="sep-line mt-4 mb-4" />
                  </div>
                  {/* separator */}
                  <div className="row">
                    <div className="col-12">
                      {/* featured-icon-box */}
                      <div className="featured-icon-box style2 left-icon icon-align-top">
                        <div className="featured-icon">
                          {/* featured-icon */}
                          <div className="ttm-icon ttm-icon_element-border ttm-icon_element-color-white ttm-icon_element-size-md">
                            <i className="flaticon flaticon-code" />
                          </div>
                        </div>
                        <div className="featured-content">
                          <div className="featured-title">
                            {/* featured title */}
                            <h5>Réalisation du projet</h5>
                          </div>
                          <div className="featured-desc">
                            {/* featured desc */}
                            <p>
                              Une fois que la solution a été conçue et validée, nous passons à sa
                              réalisation concrète. Nous procédons par étapes, en développant chaque
                              fonctionnalité de manière modulaire pour assurer une progression
                              régulière et minimiser les risques de dysfonctionnement.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* separator */}
                  <div className="separator">
                    <div className="sep-line mt-4 mb-4" />
                  </div>
                  {/* separator */}
                  <div className="row">
                    <div className="col-12">
                      {/* featured-icon-box */}
                      <div className="featured-icon-box style2 left-icon icon-align-top">
                        <div className="featured-icon">
                          {/* featured-icon */}
                          <div className="ttm-icon ttm-icon_element-border ttm-icon_element-color-white ttm-icon_element-size-md">
                            <i className="flaticon flaticon-computer" />
                          </div>
                        </div>
                        <div className="featured-content">
                          <div className="featured-title">
                            {/* featured title */}
                            <h5>Formation et maintenance</h5>
                          </div>
                          <div className="featured-desc">
                            {/* featured desc */}
                            <p>
                              Nous ne nous contentons pas de livrer la solution : nous vous
                              accompagnons dans la prise en main de vos nouveaux outils. Si
                              nécessaire, nous proposons des formations adaptées aux utilisateurs,
                              ainsi qu'un suivi régulier pour s'assurer que la solution fonctionne
                              parfaitement.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="p-4 d-block d-xl-none">
                  {/* section title */}
                  <div className="section-title with-desc clearfix">
                    <div className="title-header">
                      <h5>Pourquoi choisir House Innovation</h5>
                      <h2 className="title">
                        Nous vous accompagnons de bout en bout dans la{' '}
                        <span>digitalisation de votre structure</span>
                      </h2>
                    </div>
                    <div className="title-desc">
                      <p></p>
                    </div>
                  </div>
                  {/* section title end */}
                  <div className="row mt-40">
                    <div className="col-12">
                      {/* featured-icon-box */}
                      <div className="featured-icon-box style2 left-icon icon-align-top">
                        <div className="featured-icon">
                          {/* featured-icon */}
                          <div className="ttm-icon ttm-icon_element-border ttm-icon_element-color-white ttm-icon_element-size-md">
                            <i className="flaticon flaticon-web" />
                          </div>
                        </div>
                        <div className="featured-content">
                          <div className="featured-title">
                            {/* featured title */}
                            <h5>Analyse & Conception</h5>
                          </div>
                          <div className="featured-desc">
                            {/* featured desc */}
                            <p>
                              Nous travaillons en étroite collaboration avec vous pour comprendre
                              vos besoins spécifiques en matière de digitalisation. Nous analysons
                              votre structure et vos processus métiers, puis concevons une solution
                              sur mesure qui répond à vos attentes.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* separator */}
                  <div className="separator">
                    <div className="sep-line mt-4 mb-4" />
                  </div>
                  {/* separator */}
                  <div className="row">
                    <div className="col-12">
                      {/* featured-icon-box */}
                      <div className="featured-icon-box style2 left-icon icon-align-top">
                        <div className="featured-icon">
                          {/* featured-icon */}
                          <div className="ttm-icon ttm-icon_element-border ttm-icon_element-color-white ttm-icon_element-size-md">
                            <i className="flaticon flaticon-code" />
                          </div>
                        </div>
                        <div className="featured-content">
                          <div className="featured-title">
                            {/* featured title */}
                            <h5>Réalisation du projet</h5>
                          </div>
                          <div className="featured-desc">
                            {/* featured desc */}
                            <p>
                              Une fois que la solution a été conçue et validée, nous passons à sa
                              réalisation concrète. Nous procédons par étapes, en développant chaque
                              fonctionnalité de manière modulaire pour assurer une progression
                              régulière et minimiser les risques de dysfonctionnement.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* separator */}
                  <div className="separator">
                    <div className="sep-line mt-4 mb-4" />
                  </div>
                  {/* separator */}
                  <div className="row">
                    <div className="col-12">
                      {/* featured-icon-box */}
                      <div className="featured-icon-box style2 left-icon icon-align-top">
                        <div className="featured-icon">
                          {/* featured-icon */}
                          <div className="ttm-icon ttm-icon_element-border ttm-icon_element-color-white ttm-icon_element-size-md">
                            <i className="flaticon flaticon-computer" />
                          </div>
                        </div>
                        <div className="featured-content">
                          <div className="featured-title">
                            {/* featured title */}
                            <h5>Formation et maintenance</h5>
                          </div>
                          <div className="featured-desc">
                            {/* featured desc */}
                            <p>
                              Nous ne nous contentons pas de livrer la solution : nous vous
                              accompagnons dans la prise en main de vos nouveaux outils. Si
                              nécessaire, nous proposons des formations adaptées aux utilisateurs,
                              ainsi qu'un suivi régulier pour s'assurer que la solution fonctionne
                              parfaitement.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* whyus-section end */}

      {/* team-section */}
      <section className="ttm-row team-section res-991-pt-0 clearfix">
        <div className="container-xl">
          <div className="row align-items-center mb-5">
            <div className="col-lg-6 res-991-text-center">
              <div className="section-title style2 mb-0 clearfix">
                <div className="title-header mb-0">
                  <h5>À propos de Nous</h5>
                  <h2 className="title">
                    Rencontrez nos <span>dirigeants</span>
                  </h2>
                </div>
              </div>
            </div>
            <div className="col-lg-4 res-991-text-center">
              <div className="title-desc">
                "Le talent remporte des matchs, mais c'est le travail d'équipe et l'intelligence
                collective qui remportent des championnats." - Michael Jordan
              </div>
            </div>
            <div className="col-lg-2 text-right res-991-text-center res-991-mt-20">
              <a
                href={process.env.PUBLIC_URL + '/team'}
                className="ttm-btn ttm-btn-size-md ttm-btn-style-border ttm-btn-color-darkgrey"
              >
                Voir plus
              </a>
            </div>
          </div>

          <Slider
            className="row slick_slider slick-btn-color ttm-boxes-spacing-30px"
            {...slick_slider_team}
            slidesToShow={3}
            arrows={true}
          >
            {team.map((member) => (
              <div className="ttm-box-col-wrapper" key={member.name}>
                <div className="featured-imagebox featured-imagebox-team style1">
                  <div className="featured-thumbnail">
                    {/* featured-thumbnail */}
                    <ImageLoader src={member.image} className="img-fluid" alt={member.name} />
                    <div className="ttm-social-links-wrapper">
                      <ul className="social-icons list-inline">
                        {/* social-icons */}
                        {member.socials.facebook && (
                          <li className="social-facebook">
                            <a
                              href={member.socials.facebook}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <i className="fab fa-facebook-f" />
                            </a>
                          </li>
                        )}

                        {member.socials.twitter && (
                          <li className="social-twitter">
                            <a
                              href={member.socials.twitter}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <i className="fab fa-twitter" />
                            </a>
                          </li>
                        )}

                        {member.socials.linkedin && (
                          <li className="social-linkedin">
                            <a
                              href={member.socials.linkedin}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <i className="fab fa-linkedin-in" />
                            </a>
                          </li>
                        )}
                      </ul>
                    </div>
                  </div>
                  <div className="featured-content box-shadow">
                    <div className="featured-title">
                      {/* featured-title */}
                      <h5>{member.name}</h5>
                    </div>
                    <p className="category">{member.title}</p>
                    {/* category */}
                  </div>
                </div>
              </div>
            ))}
          </Slider>
        </div>
      </section>
      {/* team-section end*/}

      {/* process-section */}
      <section className="ttm-row process-section bg-img3 ttm-bgcolor-grey ttm-bg ttm-bgimage-yes clearfix">
        <div className="container-xl">
          <div className="row">
            <div className="col-md-12">
              {/* section title */}
              <div className="section-title text-center with-desc clearfix">
                <div className="title-header">
                  <h5>Comment ça marche</h5>
                  <h2 className="title">
                    Découvrez notre <span>processus de travail</span>
                  </h2>
                </div>
              </div>
              {/* section title end */}
            </div>
          </div>
          {/* row */}
          <div className="row">
            <div className="col-lg-12">
              <div className="ttm-processbox-wrapper">
                <div className="ttm-processbox text-center">
                  <div className="ttm-box-image">
                    <div className="img-box">
                      <img
                        className="img-fluid"
                        alt="step-one"
                        title="step-one"
                        src="images/icons/planning.png"
                      />
                    </div>
                    <div className="process-num">
                      <span className="number">01</span>
                    </div>
                  </div>
                  <div className="featured-content">
                    <div className="featured-title">
                      <h5>Choisissez un service</h5>
                    </div>
                    <div className="ttm-box-description">
                      Explorez notre liste de services et choisissez celui qui correspond le mieux à
                      vos besoins.
                    </div>
                  </div>
                </div>
                <div className="ttm-processbox text-center">
                  <div className="ttm-box-image">
                    <div className="img-box">
                      <img
                        className="img-fluid"
                        alt="step-one"
                        title="step-one"
                        src="images/icons/conversation.png"
                      />
                    </div>
                    <div className="process-num">
                      <span className="number">02</span>
                    </div>
                  </div>
                  <div className="featured-content">
                    <div className="featured-title">
                      <h5>Demander une rencontre</h5>
                    </div>
                    <div className="ttm-box-description">
                      Entrez en contact avec nous pour planifier une rencontre. Nous discuterons
                      pour mieux comprendre vos besoins et vos objectifs.
                    </div>
                  </div>
                </div>
                <div className="ttm-processbox text-center">
                  <div className="ttm-box-image">
                    <div className="img-box">
                      <img
                        className="img-fluid"
                        alt="step-one"
                        title="step-one"
                        src="images/icons/writing.png"
                      />
                    </div>
                    <div className="process-num">
                      <span className="number">03</span>
                    </div>
                  </div>
                  <div className="featured-content">
                    <div className="featured-title">
                      <h5>Recevez un plan personnalisé</h5>
                    </div>
                    <div className="ttm-box-description">
                      Nous travaillons avec vous pour élaborer un plan personnalisé qui répond à vos
                      besoins et à votre budget.
                    </div>
                  </div>
                </div>
                <div className="ttm-processbox text-center res-991-mb-0">
                  <div className="ttm-box-image">
                    <div className="img-box">
                      <img
                        className="img-fluid"
                        alt="step-one"
                        title="step-one"
                        src="images/icons/task.png"
                      />
                    </div>
                    <div className="process-num">
                      <span className="number">04</span>
                    </div>
                  </div>
                  <div className="featured-content">
                    <div className="featured-title">
                      <h5>Passons à l'action</h5>
                    </div>
                    <div className="ttm-box-description">
                      Après l'approbation du plan, nous passons à la réalisation du projet. Nous
                      nous assurons en permanence que le projet est à la hauteur de vos attentes et
                      que vous êtes satisfait.
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* row end */}
        </div>
      </section>
      {/* process-section end */}

      {/* portfolio-section */}
      <section className="ttm-row bottomzero-padding-section position-relative clearfix">
        <div className="container-xl">
          <div className="row align-items-center mb-5">
            <div className="col-lg-6 res-991-text-center">
              <div className="section-title style2 mb-0 clearfix">
                <div className="title-header mb-0">
                  <h5>Nous donnons vie à vos idées</h5>
                  <h2 className="title">
                    Découvrez nos <span>projets</span>
                  </h2>
                </div>
              </div>
            </div>
            <div className="col-lg-4 res-991-text-center">
              <div className="title-desc">
                Un projet est une entreprise temporaire dont le début et la fin sont définis et à
                laquelle des ressources sont affectées.
              </div>
            </div>
            <div className="col-lg-2 text-right res-991-text-center res-991-mt-20">
              <a
                href={process.env.PUBLIC_URL + '/projects'}
                className="ttm-btn ttm-btn-size-md ttm-btn-style-border ttm-btn-color-darkgrey"
              >
                Voir plus
              </a>
            </div>
          </div>

          {/* row */}
          <div className="row multi-columns-row ttm-boxes-spacing-10px ttm-bgcolor-white">
            {projects.fewer.map((project) => (
              <div className="ttm-box-col-wrapper col-lg-4 col-md-6 col-sm-6" key={project.name}>
                {/* featured-imagebox */}
                <div className="featured-imagebox featured-imagebox-portfolio style2">
                  {/* featured-thumbnail */}
                  <div className="featured-thumbnail">
                    <ImageLoader src={project.src} className="img-fluid" alt={project.name} />
                  </div>
                  {/* featured-thumbnail end*/}
                  <div className="featured-content">
                    <div className="category">
                      <p>{project.categories.join(', ')}</p>
                    </div>
                    <div className="featured-title">
                      <h5>
                        <a href={project.url} target="_blank" rel="noopener noreferrer">
                          {project.name}
                        </a>
                      </h5>
                    </div>
                  </div>
                </div>
                {/* featured-imagebox */}
              </div>
            ))}
          </div>
          {/* row end */}
        </div>
      </section>
      {/* portfolio-section end */}

      {/* client-section */}
      <section className="ttm-row our-partner-section ttm-bgcolor-skincolor mt_90 res-991-mt-0 res-991-p-0 clearfix">
        <section className="ttm-row bottomzero-padding-section clearfix">
          <div className="container-xl pb-5 res-991-pb-0">
            <div className="row align-items-center ttm-textcolor-white">
              <div className="col-lg-7 res-991-text-center">
                <div className="section-title style3 mb-0 clearfix">
                  <div className="title-header mb-0">
                    <h5>Nous evoluons ensemble</h5>
                    <h2 className="title">
                      Ils nous font <span className="ttm-textcolor-white">confiance</span>
                    </h2>
                  </div>
                </div>
              </div>
              <div className="col-lg-5 res-991-text-center">
                <div className="title-desc">
                  Un projet est une entreprise temporaire dont le début et la fin sont définis et à
                  laquelle des ressources sont affectées.
                </div>
              </div>
            </div>
          </div>
        </section>
        <div className="container-xl">
          <div className="row">
            <div className="col-12 text-center">
              <Slider
                className="row slick_slider ttm-boxes-spacing-30px"
                {...slick_slider_clients}
                slidesToShow={4}
                arrows={true}
              >
                {clients.map((client) => (
                  <div className="ttm-box-col-wrapper" key={client.name}>
                    <div className="client-box ttm-box-view-boxed-logo">
                      <div className="client">
                        <div
                          className="ttm-client-logo-tooltip ttm-client-logo-box"
                          data-tooltip={client.name}
                        >
                          <ImageLoader src={client.src} className="img-fluid" alt={client.name} />
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </Slider>
            </div>
          </div>
        </div>
      </section>
      {/* client-section end */}

      <section className="ttm-row bottomzero-padding-section position-relative clearfix">
        <div className="container-xl">
          <div className="row">
            <div className="col-md-12">
              {/* section title */}
              <div className="section-title text-center with-desc clearfix">
                <div className="title-header">
                  <h5>N'hésitez pas à découvrir ce que ces médias disent à notre sujet</h5>
                  <h2 className="title">
                    Ils parlent de <span>nous</span>
                  </h2>
                </div>
              </div>
              {/* section title end */}
            </div>
          </div>
        </div>
      </section>

      <section className="ttm-row mt-10 zero-padding-section clearfix">
        <div className="container-fluid p-0">
          <Slider className="row slick_slider ttm-boxes-spacing-10px" {...slick_slider_medias}>
            {medias.map((media) => (
              <div className="ttm-box-col-wrapper" key={media.name}>
                <div className="featured-imagebox featured-imagebox-portfolio style1">
                  {/* featured-thumbnail */}
                  <div className="featured-thumbnail">
                    <ImageLoader src={media.src} className="img-fluid" alt={media.name} />
                  </div>
                  {/* featured-thumbnail end*/}
                  {/* ttm-box-view-overlay */}
                  <div className="ttm-box-view-overlay">
                    <div className="featured-iconbox ttm-media-link">
                      {/* ttm-media-link */}
                      <a href={media.src} className="ttm_link">
                        <i className="ti ti-link" />
                      </a>
                    </div>
                  </div>
                  {/* ttm-box-view-overlay end*/}
                  <div className="featured-content">
                    <div className="featured-title">
                      {/* featured-title */}
                      <h5>
                        <a href={media.src}>{media.name}</a>
                      </h5>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </Slider>
        </div>
      </section>
      {/* our-partner-section end */}

      {/* partner-section */}
      <section className="ttm-row bottomzero-padding-section clearfix">
        <div className="container-xl pb-5 res-991-pb-0">
          <div className="row align-items-center">
            <div className="col-lg-7 res-991-text-center">
              <div className="section-title style3 mb-0 clearfix">
                <div className="title-header mb-0">
                  <h5>Nos partenaires</h5>
                  <h2 className="title">
                    Ils nous <span>accompangent</span>
                  </h2>
                </div>
              </div>
            </div>
            <div className="col-lg-5 res-991-text-center">
              <div className="title-desc">
                Un projet est une entreprise temporaire dont le début et la fin sont définis et à
                laquelle des ressources sont affectées.
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="ttm-row our-partner-section mt_90 res-991-mt-0 res-991-p-0 clearfix">
        <div className="container-xl">
          <div className="row">
            <div className="col-12 text-center">
              <Slider
                className="row slick_slider slick-btn-color ttm-boxes-spacing-30px"
                {...slick_slider_partners}
              >
                {partners.map((partner) => (
                  <div className="ttm-box-col-wrapper" key={partner.name}>
                    <div className="client-box ttm-box-view-boxed-logo">
                      <div className="client">
                        <div
                          className="ttm-client-logo-tooltip ttm-client-logo-box"
                          data-tooltip={partner.name}
                        >
                          <ImageLoader src={partner.src} className="img-fluid" alt={partner.name} />
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </Slider>
            </div>
          </div>
        </div>
      </section>
      {/* partner-section end */}

      {/* blog-section */}
      {(blogs || []).length > 0 && (
        <section className="ttm-row blog-section res-991-pt-0 clearfix">
          <div className="container-xl">
            <div className="row align-items-center mb-5">
              <div className="col-lg-8 res-991-text-center">
                <div className="section-title style2 mb-0 clearfix">
                  <div className="title-header mb-0">
                    <h5>Actualités</h5>
                    <h2 className="title">
                      Consultez nos <span>dernières actualités</span>
                    </h2>
                  </div>
                </div>
              </div>

              <div className="col-lg-4 text-right res-991-text-center res-991-mt-20">
                <a
                  href={process.env.PUBLIC_URL + '/projects'}
                  className="ttm-btn ttm-btn-size-md ttm-btn-style-border ttm-btn-color-darkgrey"
                >
                  Voir plus
                </a>
              </div>
            </div>

            <div className="row">
              {blogs.map((blog) => (
                <div className="col-lg-4 col-md-6" key={blog?.slug}>
                  <SingleBlog blog={blog} />
                </div>
              ))}
            </div>
          </div>
        </section>
      )}
      {/* blog-section end */}
    </div>
  );
};

export default Home;
