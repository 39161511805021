import React, { Component } from 'react';
import Header from '../layout/Header';
import projects from '../../core/data/projects.json';
import ImageLoader from '../../containers/ImageLoader';

export class Projects extends Component {
  constructor(props) {
    super();
  }

  render() {
    return (
      <div className="site-main">
        <Header />
        {/* page-title */}
        <div className="ttm-page-title-row ttm-bgcolor-grey">
          <div className="container-xl">
            <div className="row">
              <div className="col-md-12">
                <div className="title-box text-center">
                  <div className="page-title-heading">
                    <h1 className="title">Nos réalisations</h1>
                  </div>
                  {/* /.page-title-captions */}
                  <div className="breadcrumb-wrapper">
                    <span>
                      <a title="Homepage" href={process.env.PUBLIC_URL + '/'}>
                        <i className="ti ti-home" />
                        &nbsp;&nbsp;Accueil
                      </a>
                    </span>
                    <span className="ttm-bread-sep">&nbsp; : : &nbsp;</span>
                    <span>Projets</span>
                  </div>
                </div>
              </div>
              {/* /.col-md-12 */}
            </div>
            {/* /.row */}
          </div>
          {/* /.container */}
        </div>
        {/* page-title end*/}

        <div className="ttm-row grid-section clearfix">
          <div className="container-xl">
            <div className="row multi-columns-row ttm-boxes-spacing-5px">
              {projects.all.map((project) => (
                <div className="ttm-box-col-wrapper col-lg-4 col-md-6" key={project.name}>
                  {/* featured-imagebox */}
                  <div className="featured-imagebox featured-imagebox-portfolio style2">
                    {/* featured-thumbnail */}
                    <div className="featured-thumbnail">
                      <ImageLoader src={project.src} className="img-fluid" alt={project.name} />
                    </div>
                    {/* featured-thumbnail end*/}
                    <div className="featured-content">
                      <div className="category">
                        <p>{project.categories.join(', ')}</p>
                      </div>
                      <div className="featured-title">
                        <h5>
                          <a href={project.url} target="_blank" rel="noopener noreferrer">
                            {project.name}
                          </a>
                        </h5>
                      </div>
                    </div>
                  </div>
                  {/* featured-imagebox */}
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Projects;
