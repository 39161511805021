import http from '../common/api-http';

const source = 'send-mail';

export const sendMessage = async (data) => {
  try {
    const rs = await http.post(source, data);
    return Promise.resolve(rs.data.data);
  } catch (error) {
    return Promise.reject(error);
  }
};
