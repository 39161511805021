import React, { Component } from 'react';
import ImageLoader from '../../containers/ImageLoader';

export default class Footer extends Component {
  render() {
    return (
      <footer className="footer widget-footer clearfix">
        {this.props.children}
        <div className="first-footer ttm-bgcolor-skincolor">
          {/* <div className="ttm-row-wrapper-bg-layer ttm-bg-layer" /> */}
          <div className="container-xl">
            <div className="d-flex justify-content-center justify-content-md-between align-items-md-center flex-wrap">
              {/* <div className="col-lg-4 col-md-4 col-sm-12 order-md-2">
                <div className="footer-logo text-sm-center">
                  <img
                    data-src="images/footer-logo.png"
                    className="img-fluid"
                    alt="footer-logo"
                    src="images/footer-logo.png"
                  />
                </div>
              </div> */}
              <div className="col-lg-5 col-md-5 col-12 order-md-1 res-767-mt-20">
                <div className="text-left">
                  {/*  featured-icon-box */}
                  <div className="featured-icon-box left-icon icon-align-top">
                    <div className="featured-icon">
                      {/*  featured-icon */}
                      <div className="ttm-icon ttm-textcolor-white ttm-icon_element-size-md">
                        <i className="ti ti-location-pin" />
                        {/*  ttm-icon */}
                      </div>
                    </div>
                    <div className="featured-content">
                      {/*  featured-content */}
                      <div className="featured-desc text-left">
                        <p>Carrefour Lycée Makepe, Douala, Cameroun.</p>
                      </div>
                    </div>
                  </div>
                  {/*  featured-icon-box END */}
                </div>
              </div>
              <div className="col-lg-5 col-md-5 col-12 order-md-3 res-767-mt-20">
                <div className="text-left text-md-right">
                  <a
                    href="malito:info@houseinnovationgroup.com"
                    className="ttm-btn ttm-btn-size-md ttm-btn-style-border ttm-btn-color-white"
                  >
                    <i className="far fa-envelope" /> info@houseinnovationgroup.com
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="second-footer ttm-textcolor-white bg-img2">
          <div className="footer-overlay">
            <div className="container-xl">
              <div className="row">
                <div className="col-xs-12 col-sm-12 col-md-12 col-lg-5 widget-area">
                  <div className="widget widget_text clearfix">
                    <div className="footer-branding">
                      <ImageLoader
                        id="logo-img"
                        className="img-fluid"
                        alt="logo"
                        src="images/logo-full.png"
                      />
                    </div>
                    {/* <h3 className="widget-title">À propos de nous</h3> */}
                    <div className="textwidget widget-text">
                      House Innovation est une entreprise d’innovation technologique. Notre but est
                      de mettre la technologie au service des entreprises et particuliers, ceci en
                      résolvant des problèmes spécifiques grâce aux dernières avancées
                      technologiques.
                    </div>
                    <div className="quicklink-box">
                      {/*  featured-icon-box */}
                      <div className="featured-icon-box left-icon">
                        <div className="featured-icon">
                          {/*  featured-icon */}
                          <div className="ttm-icon ttm-bgcolor-skincolor ttm-icon_element-size-md rounded-circle">
                            <span className="flaticon flaticon-clock" />
                            {/*  ttm-icon */}
                          </div>
                        </div>
                        <div className="featured-content">
                          {/*  featured-content */}
                          <div className="featured-desc">
                            {/*  featured-desc */}
                            <p>Contacter notre service d'assistance</p>
                          </div>
                          <div className="featured-title">
                            {/*  featured-title */}
                            <h5>(+237) 697 098 859</h5>
                          </div>
                        </div>
                      </div>
                      {/*  featured-icon-box END */}
                    </div>
                  </div>
                </div>

                <div className="col-xs-12 col-sm-12 col-md-6 col-lg-3 widget-area">
                  <div className="widget link-widget clearfix">
                    <h3 className="widget-title">Liens utiles</h3>
                    <ul id="menu-footer-services">
                      <li>
                        <a href={process.env.PUBLIC_URL + '/aboutus'}>Qui sommes nous ?</a>
                      </li>
                      <li>
                        <a href={process.env.PUBLIC_URL + '/blogs'}>Blogs</a>
                      </li>
                      <li>
                        <a href={process.env.PUBLIC_URL + '/peojects'}>Nos réalisations</a>
                      </li>
                      {/* <li>
                        <a href={process.env.PUBLIC_URL + '/'}>Carrières</a>
                      </li> */}
                      <li>
                        <a href={process.env.PUBLIC_URL + '/contact'}>Contactez nous</a>
                      </li>
                    </ul>
                  </div>
                </div>

                <div className="col-xs-12 col-sm-12 col-md-6 col-lg-4 widget-area res-767-mb-40">
                  <div className="widget flicker_widget clearfix">
                    <h3 className="widget-title">News Letter</h3>
                    <div className="textwidget widget-text">
                      Inscrivez-vous dès aujourd'hui pour recevoir des conseils, des astuces et les
                      dernières informations sur nos services.
                      <form
                        id="subscribe-form"
                        className="newsletter-form"
                        method="post"
                        action="#"
                        data-mailchimp="true"
                      >
                        <div className="mailchimp-inputbox clearfix" id="subscribe-content">
                          <i className="fa fa-envelope" />
                          <input
                            type="email"
                            name="email"
                            placeholder="Adresse électronique.."
                            required
                          />
                          <input type="submit" value="" />
                        </div>
                        <div id="subscribe-msg" />
                      </form>
                      <h5>Suivez-nous sur</h5>
                      <div className="social-icons circle social-hover">
                        <ul className="list-inline">
                          <li className="social-facebook">
                            <a
                              className="tooltip-top rounded-circle"
                              href="https://www.facebook.com/housinnov"
                              target="_blank"
                              rel="noopener noreferrer"
                              data-tooltip="Facebook"
                            >
                              <i className="fab fa-facebook-f" />
                            </a>
                          </li>
                          <li className="social-twitter">
                            <a
                              className="tooltip-top rounded-circle"
                              href="https://twitter.com/House_INNOV"
                              target="_blank"
                              rel="noopener noreferrer"
                              data-tooltip="Twitter"
                            >
                              <i className="fab fa-twitter" />
                            </a>
                          </li>
                          <li className="social-linkedin">
                            <a
                              className=" tooltip-top rounded-circle"
                              href="https://www.linkedin.com/company/house-innovation-sarl/"
                              target="_blank"
                              rel="noopener noreferrer"
                              data-tooltip="aedIn"
                            >
                              <i className="fab fa-linkedin-in" />
                            </a>
                          </li>
                          <li className="social-instagram">
                            <a
                              className=" tooltip-top rounded-circle"
                              data-tooltip="Instagram"
                              href="https://www.instagram.com/house_innovation_group/"
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <i className="fab fa-instagram" />
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="bottom-footer-text ttm-bgcolor-darkgrey ttm-textcolor-white">
          <div className="container-xl">
            <div className="row copyright align-items-center res-767-text-center">
              <div className="col-md-6">
                <div>
                  <span>
                    Copyright © {new Date().getFullYear()}&nbsp;<a href="/">House Innovation</a>.
                  </span>
                  &nbsp;&nbsp;
                  <span className="hide-on-mobile">Tous droits réservés.</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
    );
  }
}
